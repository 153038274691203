import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import "./style.css";
import { getStudents } from "../../data_access/user";
import apiWrapper from "../../helpers/api_helper";
import AttendanceView from "./attendance_view";

export default function Attendance() {
  const user = useSelector((state) => state.user);
  const [lessons, setLessons] = useState([]);
  const [students, setStudents] = useState([]);
  const [curStudent, setCurStudent] = useState(
    sessionStorage.getItem("curStudent")
      ? sessionStorage.getItem("curStudent")
      : 0
  );

  let start_date = new Date();
  start_date.setHours(0, 0, 0, 0);
  start_date.setDate(start_date.getDate() - start_date.getDay());
  const end_date = new Date(
    start_date.getTime() + 7 * 24 * 60 * 60 * 1000 - 1000
  );
  const [curDateRange, setCurDateRange] = useState({
    start: start_date,
    end: end_date,
  });

  const getStudentsWrapped = useCallback(async () => {
    try {
      const data = await getStudents(user.token);
      setStudents(data);
      if (curStudent === 0) {
        setCurStudent(data[0]._id);
      }
    } catch (error) {
      console.log(error);
      setStudents([]);
    }
  }, [curStudent, user.token]);

  const getStudentAttendanceForDates = useCallback(async () => {
    try {
      if (curStudent === 0) return;
      const start_date = curDateRange.start.toISOString();
      const end_date = curDateRange.end.toISOString();
      var url = `${process.env.REACT_APP_BACKEND_URL}/api/get_attendance_for_student?student_id=${curStudent}&start_date=${start_date}&end_date=${end_date}`;
      if (user.role === "teacher") url += `&tacher_id=${user.id}`;
      const data = await apiWrapper.get(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const mapped = data.map((pres) => {
        return {
          start: new Date(pres.lesson.start_time),
          end: new Date(pres.lesson.end_time),
          title: pres.lesson.title,
          canceled: pres.lesson.canceled,
          canceled_reason: pres.lesson.canceled_reason,
          present: pres.present,
          teacher_name: `${pres.teacher.first_name} ${pres.teacher.last_name}`,
          id: pres.lesson._id,
        };
      });
      setLessons(mapped);
    } catch (error) {
      console.log(error);
      setLessons([]);
    }
  }, [user.token, user.id, user.role, curStudent, curDateRange]);

  useEffect(() => {
    getStudentsWrapped();
  }, [getStudentsWrapped]);

  useEffect(() => {
    getStudentAttendanceForDates();
  }, [curStudent, getStudentAttendanceForDates]);

  const changeStudent = (event) => {
    sessionStorage.setItem("curStudent", event.target.value);
    setCurStudent(event.target.value);
  };

  return (
    <div className="attendance">
      <Header page="attendance" />
      <SubHeader />
      <div className="attendance_middle">
        <div className="attendance_header">דו"ח נוכחות לתלמיד</div>
        <div className="student_select" dir="rtl">
          <label>תלמיד: </label>
          <select value={curStudent} onChange={changeStudent}>
            {students.map((entry) => (
              <option key={entry._id} value={entry._id}>
                {entry.first_name}&nbsp;{entry.last_name}
              </option>
            ))}
          </select>
        </div>
        <AttendanceView
          lessons={lessons}
          dateRangeChanged={setCurDateRange}
          toolbar={true}
        />
      </div>
    </div>
  );
}
