import "./style.css";
import Trash2 from "../../svg/Trash2";
import { randomString } from "../../functions/randString";

export default function ListItem({ deleteItem, labelClick, itemKey, labels }) {
  document.documentElement.style.setProperty(
    "--itemEntryColumnNum",
    labels.length
  );
  return (
    <div dir="rtl" className="item_entry">
      {labels.map((label) => (
        <div
          key={randomString()}
          className="item_label"
          onClick={() => {
            labelClick({ itemKey });
          }}
        >
          {label}
        </div>
      ))}
      <div
        key="trasher"
        className="item_trash"
        onClick={() => {
          deleteItem({ itemKey });
        }}
      >
        <Trash2 width="40" height="40" />
      </div>
    </div>
  );
}
