import { useSelector } from "react-redux";
import "./style.css";
import apiWrapper from "../../helpers/api_helper";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import GridLoader from "react-spinners/GridLoader";
import AttendanceView from "../attendance/attendance_view";
import { useParams } from "react-router-dom";
import { getUserById } from "../../data_access/user";

export default function StudentHome() {
  const { uid } = useParams();
  let user = useSelector((state) => state.user);
  let user_id = user.id;
  const token = user.token;
  if (uid) {
    user_id = uid;
  }
  const [lessons, setLessons] = useState([]);
  const emptyUserData = useMemo(
    () => ({
      first_name: "",
      last_name: "",
      mobile: "",
      children: [],
    }),
    []
  );
  const userData = useRef(emptyUserData);
  const [loading, setLoading] = useState(false);
  const [curChild, setCurChild] = useState(0);

  const setUserData = (newUserData) => {
    userData.current = newUserData;
  };

  let start_date = new Date();
  start_date.setHours(0, 0, 0, 0);
  start_date.setDate(start_date.getDate() - start_date.getDay());
  const end_date = new Date(
    start_date.getTime() + 7 * 24 * 60 * 60 * 1000 - 1000
  );
  const [curDateRange, setCurDateRange] = useState({
    start: start_date,
    end: end_date,
  });

  const getUser = useCallback(async () => {
    try {
      console.log("getUser");
      const data = await getUserById(token, user_id);
      setUserData(data);
    } catch (error) {
      console.log(error);
    }
  }, [user_id, token]);

  const getStudentAttendanceForDates = useCallback(async () => {
    try {
      console.log("getStudentAttendanceForDates");
      let curStudent = user_id;
      if (userData.current.role === "parent") {
        if (userData.current.children.length === 0) return;
        curStudent = userData.current.children[curChild]._id;
        console.log("parent, get data for child: ", curStudent);
      }
      const start_date = curDateRange.start.toISOString();
      const end_date = curDateRange.end.toISOString();
      var url = `${process.env.REACT_APP_BACKEND_URL}/api/get_attendance_for_student?student_id=${curStudent}&start_date=${start_date}&end_date=${end_date}`;
      const data = await apiWrapper.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const mapped = data.map((pres) => {
        return {
          start: new Date(pres.lesson.start_time),
          end: new Date(pres.lesson.end_time),
          title: pres.lesson.title,
          canceled: pres.lesson.canceled,
          canceled_reason: pres.lesson.canceled_reason,
          present: pres.present,
          teacher_name: `${pres.teacher.first_name} ${pres.teacher.last_name}`,
          id: pres.lesson._id,
        };
      });
      console.log("setLessons To: ", mapped);
      setLessons(mapped);
    } catch (error) {
      console.log(error);
      setLessons([]);
    }
  }, [token, user_id, curChild, curDateRange.start, curDateRange.end]);

  useEffect(() => {
    setLoading(true);
    getUser();
    getStudentAttendanceForDates();
    setLoading(false);
  }, [getStudentAttendanceForDates, getUser]);

  if (userData.current.role === "parent") {
    if (userData.current.children.length === 0) {
      return <div>לא הוגדרו ילדים במערכת</div>;
    }
    const student_name =
      userData.current.children[curChild].first_name +
      " " +
      userData.current.children[curChild].last_name;

    return (
      <div className="student_schedule">
        <GridLoader
          color="#F7931E"
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <div className="student_schedule_header">
          <div className="student_name">שם תלמיד/ה: {student_name}</div>
          {userData.current.children.length > 1 && (
            <div className="childSelect">
              <select
                value={curChild}
                onChange={(e) => {
                  setLoading(true);
                  setCurChild(e.target.value);
                }}
              >
                {userData.current.children.map((c, index) => {
                  return (
                    <option value={index} key={index}>
                      {c.first_name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
        <AttendanceView
          lessons={lessons}
          dateRangeChanged={setCurDateRange}
          toolbar={true}
        />
      </div>
    );
  } else {
    const student_name =
      userData.current.first_name + " " + userData.current.last_name;
    return (
      <div className="student_schedule">
        <GridLoader
          color="#F7931E"
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <div className="student_schedule_header">
          <div className="student_name">מערכת לתלמיד/ה: {student_name}</div>
        </div>
        <AttendanceView
          lessons={lessons}
          dateRangeChanged={setCurDateRange}
          toolbar={true}
        />
      </div>
    );
  }
}
