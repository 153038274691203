function Edit({ width = 50, height = 50 }) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 477.57 477.33"
    >
      <path
        d="m377.49,0c65.54,1.12,105.26,60.09,99.53,104.22-2.86,21.99-10.87,39.47-26.15,54.7-80.06,79.82-160.02,159.73-239.9,239.73-5.06,5.07-10.54,8.75-17.27,11.25-57.83,21.4-115.56,43.03-173.37,64.49-5.7,2.12-11.73,5.57-17.38-.34-5.62-5.88-1.86-11.85.24-17.5,21.77-58.47,43.75-116.86,65.41-175.37,1.87-5.05,4.51-9.06,8.2-12.75C157.63,187.8,238.48,107.21,319.16,26.45,336.17,9.43,356.35.54,377.49,0Zm-238.65,323.76c1.71-1.61,3.29-3.01,4.78-4.5,77.26-77.26,154.47-154.58,231.9-231.67,4.84-4.82,3.74-7.14-.47-11.15-11.22-10.7-22.38-21.5-32.83-32.92-4.99-5.45-7.48-3.95-11.77.49-13.85,14.33-28.14,28.23-42.24,42.32-62.14,62.1-124.2,124.29-186.55,186.18-5.69,5.65-6.75,8.67-.23,14.48,11.28,10.05,21.57,21.21,32.29,31.9,1.56,1.55,3.19,3.04,5.13,4.88Zm301.29-181.48c-1.91-2.2-2.75-3.3-3.72-4.28-12.52-12.55-25.21-24.94-37.51-37.7-4.29-4.44-6.2-1.01-8.73,1.52-77.32,77.3-154.6,154.63-232.07,231.79-4.27,4.25-3.63,6.36.26,10.1,11.18,10.75,22.29,21.6,32.8,33.01,5.06,5.5,7.85,4.89,12.88-.15,76.68-76.89,153.58-153.56,230.47-230.25,1.56-1.55,3.61-2.61,5.63-4.04ZM83.17,300.64c-8.92,24.03-18,48.83-27.39,73.52-1.71,4.51-2.57,7.83,1.54,11.75,11.75,11.22,23.11,22.87,34.53,34.43,2.55,2.59,4.93,3.86,8.75,2.4,25.6-9.78,51.27-19.37,76.09-28.71-31.14-31.09-62.12-62.03-93.53-93.4ZM356.99,25.71c31.71,31.67,63.66,63.58,95.2,95.09,10.75-23.74,3.76-53.41-17.8-76.24-18.11-19.18-54.03-31.32-77.4-18.85Z"
        fill="#2d356a"
      />
      <path
        d="m138.84,323.76c-1.94-1.84-3.57-3.32-5.13-4.88-10.72-10.68-21.01-21.84-32.29-31.9-6.52-5.81-5.46-8.83.23-14.48,62.35-61.89,124.41-124.08,186.55-186.18,14.1-14.09,28.39-27.99,42.24-42.32,4.29-4.44,6.78-5.94,11.77-.49,10.46,11.43,21.61,22.23,32.83,32.92,4.21,4.01,5.31,6.33.47,11.15-77.43,77.09-154.63,154.41-231.9,231.67-1.49,1.49-3.07,2.89-4.78,4.5Z"
        fill="#fcc031"
      />
      <path
        d="m440.13,142.28c-2.02,1.43-4.08,2.48-5.63,4.04-76.89,76.68-153.79,153.36-230.47,230.25-5.03,5.05-7.82,5.65-12.88.15-10.5-11.4-21.61-22.26-32.8-33.01-3.89-3.74-4.53-5.85-.26-10.1,77.47-77.15,154.75-154.49,232.07-231.79,2.53-2.53,4.45-5.96,8.73-1.52,12.31,12.76,24.99,25.15,37.51,37.7.97.97,1.81,2.08,3.72,4.28Z"
        fill="#fcc031"
      />
      <path
        d="m83.17,300.64c31.42,31.37,62.4,62.31,93.53,93.4-24.82,9.34-50.49,18.93-76.09,28.71-3.82,1.46-6.2.18-8.75-2.4-11.42-11.57-22.78-23.21-34.53-34.43-4.11-3.93-3.26-7.24-1.54-11.75,9.39-24.69,18.47-49.49,27.39-73.52Z"
        fill="#fefefe"
      />
      <path
        d="m356.99,25.71c23.38-12.47,59.29-.33,77.4,18.85,21.56,22.83,28.55,52.5,17.8,76.24-31.55-31.51-63.5-63.42-95.2-95.09Z"
        fill="#bce5f2"
      />
    </svg>
  );
}

export default Edit;
