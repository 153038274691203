import apiWrapper from "../helpers/api_helper";

const clearCacheForAllLessonsAPIs = () => {
  for (let k of Object.keys(sessionStorage)) {
    if (k.startsWith("presence") || k.startsWith("lesson")) {
      sessionStorage.removeItem(k);
    }
  }
};

export const getCurTeacherLessons = async (token) => {
  try {
    const data = await apiWrapper.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/lessons`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const getLessonsForTeacherInDates = async (
  token,
  teacher_id,
  start_date,
  end_date
) => {
  try {
    var teacher_param = `teacher_id=${teacher_id}&`;
    if (teacher_id === "ALL") teacher_param = "";
    const data = await apiWrapper.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/get_lessons_with_students?${teacher_param}start_date=${start_date}&end_date=${end_date}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getLesson = async (token, lesson_id) => {
  try {
    const cacheKey = `lesson:${lesson_id}`;
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return JSON.parse(cached);
    }
    const data = await apiWrapper.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/lesson?lesson_id=${lesson_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem(cacheKey, JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createLesson = async (
  token,
  teacher_id,
  start_date,
  end_date,
  title
) => {
  try {
    const data = await apiWrapper.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/lesson`,
      {
        teacher_id,
        start_date,
        end_date,
        title,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateLesson = async (
  token,
  lesson_id,
  title = null,
  start_date = null,
  end_date = null,
  canceled = false,
  canceled_reason = ""
) => {
  try {
    let updateBody = {
      lesson_id,
      canceled,
      canceled_reason,
    };
    if (title) updateBody["title"] = title;
    if (start_date) updateBody["start_date"] = start_date;
    if (end_date) updateBody["end_date"] = end_date;
    await apiWrapper.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/lesson`,
      updateBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.removeItem(`lesson:${lesson_id}`);
    clearCacheForAllLessonsAPIs();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteLesson = async (token, lesson_id) => {
  try {
    await apiWrapper.delete(`${process.env.REACT_APP_BACKEND_URL}/api/lesson`, {
      data: {
        lesson: lesson_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    sessionStorage.removeItem(`lesson:${lesson_id}`);
    clearCacheForAllLessonsAPIs();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getPresenceForLesson = async (token, lesson_id) => {
  try {
    const cacheKey = `presence:${lesson_id}`;
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return JSON.parse(cached);
    }
    const data = await apiWrapper.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/get_presence?lesson=${lesson_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem(cacheKey, JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updatePresenceForLesson = async (
  token,
  lesson_id,
  presenceToUpdate
) => {
  try {
    await apiWrapper.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/presence?lesson_id=${lesson_id}`,
      presenceToUpdate,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.removeItem(`presence:${lesson_id}`);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deletePresenceForLesson = async (token, lesson_id, student_id) => {
  try {
    await apiWrapper.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/presence`,
      {
        data: {
          lesson: lesson_id,
          student: student_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.removeItem(`presence:${lesson_id}`);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const copyLastWeekLessons = async (token, toWeek) => {
  try {
    await apiWrapper.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/copy_lastweek_lessons`,
      {
        toweek: toWeek,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};
