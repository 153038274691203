import Cross from "../../svg/Cross";
import "./style.css";

export default function Event({ event }) {
  let students = "";
  if (Object.keys(event).includes("students")) {
    students = event.students.join(", ");
  }
  if (event.canceled) {
    return (
      <div className="custom_event">
        <div className="event_canceled">
          <Cross width="100%" height="100%" color="#bb0000" />
        </div>
        <div>
          <strong>{event.title}</strong>
        </div>
        <div>{students}</div>
      </div>
    );
  }
  return (
    <div>
      <div>
        <strong>{event.title}</strong>
      </div>
      <div>{students}</div>
    </div>
  );
}
