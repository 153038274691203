import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import "./users.css";
import { Formik, Form } from "formik";
import LoginInput from "../../components/inputs/login";
import * as Yup from "yup";
import GridLoader from "react-spinners/GridLoader";
import { createUser } from "../../data_access/user";
const formInfo = {
  first_name: "",
  last_name: "",
  mobile: "",
};

export default function AddUser() {
  const user = useSelector((state) => state.user);
  const { role } = useParams();
  const navigate = useNavigate();
  const [formdata, setFormdata] = useState(formInfo);
  const { mobile, first_name, last_name } = formdata;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };
  const addUserValidation = Yup.object({
    mobile: Yup.string()
      .required("Mobile is required")
      .matches(/^05\d\d\d\d\d\d\d\d/, "please provide a valid phone number"),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const submitNewUserForm = async () => {
    try {
      setLoading(true);
      await createUser(user.token, mobile, first_name, last_name, role);
      setError("");
      setLoading(false);
      navigate(`/users/${role}`);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  return (
    <div className="users">
      <Header page="add_user" />
      <SubHeader />
      <div className="users_middle" dir="rtl">
        <Formik
          enableReinitialize
          validationSchema={addUserValidation}
          initialValues={{
            mobile,
            first_name,
            last_name,
          }}
          onSubmit={() => {
            submitNewUserForm();
          }}
        >
          {(formik) => (
            <Form>
              <LoginInput
                type="number"
                name="mobile"
                placeholder="טלפון נייד"
                onChange={handleChange}
              />
              <LoginInput
                type="text"
                name="first_name"
                placeholder="שם פרטי"
                onChange={handleChange}
              />
              <LoginInput
                type="text"
                name="last_name"
                placeholder="שם משפחה"
                onChange={handleChange}
              />
              <GridLoader
                color="#F7931E"
                loading={loading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <button type="submit" className="main_btn">
                הוספה
              </button>
              {error && <div className="error_text">{error}</div>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
