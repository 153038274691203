import { useParams } from "react-router-dom";
import "./style.css";

export default function PerStudentPDF() {
  const { id, start, end } = useParams();
  const pdf_url = `${process.env.REACT_APP_BACKEND_URL}/api/s_att_pdf?student_id=${id}&start_date=${start}&end_date=${end}`;
  return (
    <div className="pdf_container">
      <iframe
        src={pdf_url}
        title="דוח נוכחות לתלמיד"
        // type="application/pdf"
        width="100%"
        height="2400px"
      ></iframe>
    </div>
  );
}
