import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import "./style.css";
import { getStudents } from "../../data_access/user";
import apiWrapper from "../../helpers/api_helper";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import he from "date-fns/locale/he";

export default function PerStudentReport() {
  const user = useSelector((state) => state.user);
  const [students, setStudents] = useState([]);
  const [curStudent, setCurStudent] = useState(0);
  const initalAttendanceSummary = useMemo(
    () => ({
      attendanceTable: {},
      numLessons: 0,
      numLessonsAttended: 0,
      numLessonsNotAttended: 0,
      numLessonsNotReported: 0,
      numLessonsCanceled: 0,
      numLateArrivals: 0,
      numEarlyDeparture: 0,
      numDaysArrived: 0,
      numDaysWithLessons: 0,
    }),
    []
  );

  let start_date = new Date();
  start_date.setDate(start_date.getDate() - start_date.getDay());
  let end_date = new Date(start_date);
  end_date.setDate(end_date.getDate() + 7);

  const [startDate, setStartDate] = useState(start_date);
  const [endDate, setEndDate] = useState(end_date);
  registerLocale("he", he); // register it with the name you want
  setDefaultLocale("he");

  const [attendanceSummary, setAttendanceSummary] = useState(
    initalAttendanceSummary
  );

  const getStudentsWrapped = useCallback(async () => {
    try {
      const data = await getStudents(user.token);
      setStudents(data);
      if (curStudent === 0) {
        setCurStudent(data[0]._id);
      }
    } catch (error) {
      console.log(error);
      setStudents([]);
    }
  }, [curStudent, user.token]);

  const didAttend = (attendance) => {
    return (
      attendance.attended ||
      attendance.present === "yes" ||
      attendance.present === "partial"
    );
  };

  const setAttendanceSummaryFromAPI = useCallback((data) => {
    let attendanceTable = {};
    for (const l of data) {
      if (Object.keys(attendanceTable).includes(l.lesson.title)) {
        attendanceTable[l.lesson.title]["numLessons"] += 1;
        if (didAttend(l)) attendanceTable[l.lesson.title]["attended"] += 1;
        if (l.present === "no")
          attendanceTable[l.lesson.title]["notAttended"] += 1;
        if (l.was_late) attendanceTable[l.lesson.title]["wasLate"] += 1;
        if (l.left_early) attendanceTable[l.lesson.title]["leftEarly"] += 1;
        if (l.lesson.canceled) attendanceTable[l.lesson.title]["canceled"] += 1;
      } else {
        attendanceTable[l.lesson.title] = {
          numLessons: 1,
          attended: didAttend(l) ? 1 : 0,
          notAttended: l.present === "no" ? 1 : 0,
          wasLate: l.was_late ? 1 : 0,
          leftEarly: l.left_early ? 1 : 0,
          canceled: l.lesson.canceled ? 1 : 0,
        };
      }
    }
    const lessonsAttended = data.filter((lesson) => didAttend(lesson));
    const lessonsNotAttended = data.filter((lesson) => lesson.present === "no");
    const lessonsLate = data.filter((lesson) => lesson.was_late);
    const lessonsLeftEarly = data.filter((lesson) => lesson.left_early);
    const lessonsCanceled = data.filter((lesson) => {
      return lesson.lesson.canceled;
    });
    let visitedDays = [];
    let allDays = [];
    for (let attendance of data) {
      let lessonDay = new Date(attendance.lesson.start_time);
      if (attendance.lesson.canceled) continue;
      lessonDay.setHours(9, 0, 0, 0);
      if (!allDays.includes(lessonDay.getTime()))
        allDays.push(lessonDay.getTime());
      if (didAttend(attendance)) {
        if (!visitedDays.includes(lessonDay.getTime()))
          visitedDays.push(lessonDay.getTime());
      }
    }
    setAttendanceSummary({
      attendanceTable: attendanceTable,
      numLessons: data.length,
      numLessonsAttended: lessonsAttended.length,
      numLessonsNotAttended: lessonsNotAttended.length,
      numLessonsNotReported:
        data.length - lessonsAttended.length - lessonsNotAttended.length,
      numLessonsCanceled: lessonsCanceled.length,
      numLateArrivals: lessonsLate.length,
      numEarlyDeparture: lessonsLeftEarly.length,
      numDaysArrived: visitedDays.length,
      numDaysWithLessons: allDays.length,
    });
  }, []);

  const getStudentAttendanceForDates = useCallback(async () => {
    try {
      if (curStudent === 0) return;
      const start_date = startDate.toISOString();
      const end_date = endDate.toISOString();
      var url = `${process.env.REACT_APP_BACKEND_URL}/api/get_attendance_for_student?student_id=${curStudent}&start_date=${start_date}&end_date=${end_date}`;
      if (user.role === "teacher") url += `&tacher_id=${user.id}`;
      const data = await apiWrapper.get(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setAttendanceSummaryFromAPI(data);
    } catch (error) {
      console.log(error);
      setAttendanceSummary(initalAttendanceSummary);
    }
  }, [
    user,
    curStudent,
    startDate,
    endDate,
    initalAttendanceSummary,
    setAttendanceSummaryFromAPI,
  ]);

  useEffect(() => {
    getStudentsWrapped();
  }, [getStudentsWrapped]);

  useEffect(() => {
    getStudentAttendanceForDates();
  }, [curStudent, getStudentAttendanceForDates]);

  const changeStudent = (event) => {
    setCurStudent(event.target.value);
  };

  return (
    <div className="reports">
      <Header page="reports" />
      <SubHeader />
      <div className="reports_middle">
        <div className="reports_header">דו"ח נוכחות לתלמיד</div>
        <div className="reports_student_select" dir="rtl">
          <label>תלמיד: </label>
          <select value={curStudent} onChange={changeStudent}>
            {students.map((entry) => (
              <option key={entry._id} value={entry._id}>
                {entry.first_name}&nbsp;{entry.last_name}
              </option>
            ))}
          </select>
        </div>
        <div className="reports_date_range" dir="rtl">
          <div>
            <label>מתאריך: </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              popperPlacement="right"
              locale="he"
            />
          </div>
          <div>
            <label>עד תאריך: </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              popperPlacement="left"
              locale="he"
            />
          </div>
          <button
            className="reports_buttons_button"
            onClick={() => {
              const newURL = `/per_student_pdf/${curStudent}/${startDate.toISOString()}/${endDate.toISOString()}`;
              window.open(newURL, "_blank");
              console.log(
                `id: ${curStudent} report from: ${startDate} till: ${endDate}`
              );
            }}
          >
            דו"ח להדפסה
          </button>
        </div>
        <div className="reports_summary_table reports_summary_table_header">
          <div className="rst_label">שיעורים</div>
          <div className="rst_label">סה"כ בתקופה</div>
          <div className="rst_label">מתוכם נכח</div>
          <div className="rst_label">החסיר</div>
          <div className="rst_label">ללא דייוח</div>
          <div className="rst_label">בוטלו</div>
          <div className="rst_label">איחורים</div>
          <div className="rst_label">יציאה מוקדמת</div>
        </div>
        {Object.keys(attendanceSummary.attendanceTable).map((k) => {
          return (
            <div className="reports_summary_table">
              <div className="rst_label">{k}</div>
              <div className="rst_label">
                {attendanceSummary.attendanceTable[k].numLessons}
              </div>
              <div className="rst_label">
                {attendanceSummary.attendanceTable[k].attended}
              </div>
              <div className="rst_label">
                {attendanceSummary.attendanceTable[k].notAttended}
              </div>
              <div className="rst_label">
                {attendanceSummary.attendanceTable[k].numLessons -
                  attendanceSummary.attendanceTable[k].attended -
                  attendanceSummary.attendanceTable[k].notAttended}
              </div>
              <div className="rst_label">
                {attendanceSummary.attendanceTable[k].canceled}
              </div>
              <div className="rst_label">
                {attendanceSummary.attendanceTable[k].wasLate}
              </div>
              <div className="rst_label">
                {attendanceSummary.attendanceTable[k].leftEarly}
              </div>
            </div>
          );
        })}
        <hr />
        <div className="reports_summary_table">
          <div className="rst_label">בכל המקצועות</div>
          <div className="rst_label">{attendanceSummary.numLessons}</div>
          <div className="rst_label">
            {attendanceSummary.numLessonsAttended}
          </div>
          <div className="rst_label">
            {attendanceSummary.numLessonsNotAttended}
          </div>
          <div className="rst_label">
            {attendanceSummary.numLessonsNotReported}
          </div>
          <div className="rst_label">
            {attendanceSummary.numLessonsCanceled}
          </div>
          <div className="rst_label">{attendanceSummary.numLateArrivals}</div>
          <div className="rst_label">{attendanceSummary.numEarlyDeparture}</div>
        </div>
        <div className="report_total_days">
          ימי נוכחות: {attendanceSummary.numDaysArrived} מ-
          {attendanceSummary.numDaysWithLessons}
        </div>
      </div>
    </div>
  );
}
