function Attendance({ width = 32, height = 32 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 567.13 528.25">
      <g>
        <path d="m65.41,116.26c10.43,30.81,28.5,38.88,51.9,22.59-9.68,19.89-19.37,39.77-29.53,60.63,22.89,6.05,44.68,12.61,63.01,32.91-17.86,2.18-33.09,4.95-48.42,5.72-31.35,1.57-61.72-4.28-91.44-14.15-8.24-2.74-11.99-7.8-10.69-16.02,2.82-17.78,5.63-35.58,9.36-53.19,2.77-13.11,11.95-20.68,24.5-25.13,10.51-3.72,20.56-8.73,31.3-13.37Z" />
        <path d="m94.09,0c7.01,2.73,19.98,6.75,31.93,12.79,8.44,4.26,13.23,12.56,13.72,22.61,1.05,21.17.71,42.24-10.09,61.3-3.96,7-9,13.86-14.99,19.13-13.97,12.29-29.95,12.09-42.92-1.3-15.34-15.84-24.38-34.68-20.84-57.78.44-2.9-.6-6.03-.99-9.04C46.82,23.29,65.15.18,94.09,0Z" />
        <path
          d="m174.25,235.72c-14.64-25.55-36.2-40.14-62.92-48.86,6.71-13.75,12.93-26.47,20.47-41.9,14.65,13.09,28.38,25.35,42.67,38.12,23.19-24.42,45.7-51.39,78.69-65.72-36.73,31.67-57.28,74.12-78.91,118.36Z"
          fill="#3aaa35"
        />
        <path d="m122.01,116.39c11.86,5.1,23.49,10.39,35.34,15.1,9.89,3.93,16.56,10.62,19.32,20.79.67,2.48-.57,5.48-1.03,9.01-17.58-13.13-33.53-25.04-49.8-37.19-2.56,3.36-4.13,5.41-6.11,8,.87-5.98,1.63-11.23,2.28-15.72Z" />
      </g>
      <g>
        <path d="m379.38,116.26c10.43,30.81,28.5,38.88,51.9,22.59-9.68,19.89-19.37,39.77-29.53,60.63,22.89,6.05,44.68,12.61,63.01,32.91-17.86,2.18-33.09,4.95-48.42,5.72-31.35,1.57-61.72-4.28-91.44-14.15-8.24-2.74-11.99-7.8-10.69-16.02,2.82-17.78,5.63-35.58,9.36-53.19,2.77-13.11,11.95-20.68,24.5-25.13,10.51-3.72,20.56-8.73,31.3-13.37Z" />
        <path d="m408.06,0c7.01,2.73,19.98,6.75,31.93,12.79,8.44,4.26,13.23,12.56,13.72,22.61,1.05,21.17.71,42.24-10.09,61.3-3.96,7-9,13.86-14.99,19.13-13.97,12.29-29.95,12.09-42.92-1.3-15.34-15.84-24.38-34.68-20.84-57.78.44-2.9-.6-6.03-.99-9.04-3.11-24.42,15.22-47.52,44.17-47.7Z" />
        <path
          d="m488.22,235.72c-14.64-25.55-36.2-40.14-62.92-48.86,6.71-13.75,12.93-26.47,20.47-41.9,14.65,13.09,28.38,25.35,42.67,38.12,23.19-24.42,45.7-51.39,78.69-65.72-36.73,31.67-57.28,74.12-78.91,118.36Z"
          fill="#e84421"
        />
        <path d="m435.97,116.39c11.86,5.1,23.49,10.39,35.34,15.1,9.89,3.93,16.56,10.62,19.32,20.79.67,2.48-.57,5.48-1.03,9.01-17.58-13.13-33.53-25.04-49.8-37.19-2.56,3.36-4.13,5.41-6.11,8,.87-5.98,1.63-11.23,2.28-15.72Z" />
      </g>
      <g>
        <path d="m65.41,406.14c10.43,30.81,28.5,38.88,51.9,22.59-9.68,19.89-19.37,39.77-29.53,60.63,22.89,6.05,44.68,12.61,63.01,32.91-17.86,2.18-33.09,4.95-48.42,5.72-31.35,1.57-61.72-4.28-91.44-14.15-8.24-2.74-11.99-7.8-10.69-16.02,2.82-17.78,5.63-35.58,9.36-53.19,2.77-13.11,11.95-20.68,24.5-25.13,10.51-3.72,20.56-8.73,31.3-13.37Z" />
        <path d="m94.09,289.88c7.01,2.73,19.98,6.75,31.93,12.79,8.44,4.26,13.23,12.56,13.72,22.61,1.05,21.17.71,42.24-10.09,61.3-3.96,7-9,13.86-14.99,19.13-13.97,12.29-29.95,12.09-42.92-1.3-15.34-15.84-24.38-34.68-20.84-57.78.44-2.9-.6-6.03-.99-9.04-3.11-24.42,15.22-47.52,44.17-47.7Z" />
        <path
          d="m174.25,525.59c-14.64-25.55-36.2-40.14-62.92-48.86,6.71-13.75,12.93-26.47,20.47-41.9,14.65,13.09,28.38,25.35,42.67,38.12,23.19-24.42,45.7-51.39,78.69-65.72-36.73,31.67-57.28,74.12-78.91,118.36Z"
          fill="#3aaa35"
        />
        <path d="m122.01,406.27c11.86,5.1,23.49,10.39,35.34,15.1,9.89,3.93,16.56,10.62,19.32,20.79.67,2.48-.57,5.48-1.03,9.01-17.58-13.13-33.53-25.04-49.8-37.19-2.56,3.36-4.13,5.41-6.11,8,.87-5.98,1.63-11.23,2.28-15.72Z" />
      </g>
      <g>
        <path d="m379.38,406.14c10.43,30.81,28.5,38.88,51.9,22.59-9.68,19.89-19.37,39.77-29.53,60.63,22.89,6.05,44.68,12.61,63.01,32.91-17.86,2.18-33.09,4.95-48.42,5.72-31.35,1.57-61.72-4.28-91.44-14.15-8.24-2.74-11.99-7.8-10.69-16.02,2.82-17.78,5.63-35.58,9.36-53.19,2.77-13.11,11.95-20.68,24.5-25.13,10.51-3.72,20.56-8.73,31.3-13.37Z" />
        <path d="m408.06,289.88c7.01,2.73,19.98,6.75,31.93,12.79,8.44,4.26,13.23,12.56,13.72,22.61,1.05,21.17.71,42.24-10.09,61.3-3.96,7-9,13.86-14.99,19.13-13.97,12.29-29.95,12.09-42.92-1.3-15.34-15.84-24.38-34.68-20.84-57.78.44-2.9-.6-6.03-.99-9.04-3.11-24.42,15.22-47.52,44.17-47.7Z" />
        <path
          d="m488.22,525.59c-14.64-25.55-36.2-40.14-62.92-48.86,6.71-13.75,12.93-26.47,20.47-41.9,14.65,13.09,28.38,25.35,42.67,38.12,23.19-24.42,45.7-51.39,78.69-65.72-36.73,31.67-57.28,74.12-78.91,118.36Z"
          fill="#3aaa35"
        />
        <path d="m435.97,406.27c11.86,5.1,23.49,10.39,35.34,15.1,9.89,3.93,16.56,10.62,19.32,20.79.67,2.48-.57,5.48-1.03,9.01-17.58-13.13-33.53-25.04-49.8-37.19-2.56,3.36-4.13,5.41-6.11,8,.87-5.98,1.63-11.23,2.28-15.72Z" />
      </g>
    </svg>
  );
}

export default Attendance;
