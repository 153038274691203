import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import { useNavigate } from "react-router-dom";
import "./style.css";
import * as SVGIcons from "../../svg/AdminIcons";
import { createElement } from "react";
import { Link } from "react-router-dom";

const LinkBubble = ({ label, to, icon = "Teacher" }) => {
  return (
    <Link to={to}>
      <div className="linkBubble">
        <div className="linkBubbleIcon">
          {createElement(SVGIcons[icon], {
            fontSize: "3em",
          })}
        </div>
        <div className="linkBubbleText">{label}</div>
      </div>
    </Link>
  );
};

export default function Reports() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <div className="reports">
      <Header page="reports" />
      <SubHeader />
      <div className="reports_middle">
        <div className="reports_header">דו״חות נוכחות</div>
        <div className="reports_buttons">
          <LinkBubble
            to="/per_student_report"
            label="נוכחות לפי תלמיד"
            icon="Student"
          />
          <LinkBubble
            to="/per_subject_report"
            label="נוכחות לפי נושא"
            icon="Teacher"
          />
        </div>
      </div>
    </div>
  );
}
