import * as React from "react";
const Reports = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13 11.94c0 .583-.477 1.06-1.06 1.06H2.06c-.582 0-1.059-.477-1.059-1.06V2.06c0-.583.477-1.06 1.06-1.06h9.88C12.523 1 13 1.478 13 2.06v9.88zm0-7.17l-1.084-.73a1.275 1.275 0 10-2.276.45L7.297 8.075a1.27 1.27 0 00-1.12.265L4.61 7.437a1.275 1.275 0 10-2.438.317L1 8.84v1.156l1.74-1.614a1.27 1.27 0 001.478-.193l1.53.881a1.275 1.275 0 102.26-.536l2.327-3.558a1.269 1.269 0 001.123-.222L13 5.792V4.77z" />
  </svg>
);
export default Reports;

