import * as React from "react";
const Parent = (props) => (
  <svg
    height="1em"
    width="1em"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-351 153 256 256"
    {...props}
  >
    <path d="M-184.5 155.4c11.5 0 20.9 9.4 20.9 20.9 0 11.5-9.4 20.9-20.9 20.9s-20.9-9.4-20.9-20.9c0-11.5 9.4-20.9 20.9-20.9zm68.3 83.8l-29.1-29.1c-5.1-5.1-11.8-7.6-18.5-7.6H-205.9c-6.7 0-13.4 2.6-18.5 7.7l-50.6 50.6c-3.6 3.6-3.6 9.4 0 12.9 3.6 3.6 9.4 3.6 12.9 0l44.2-44.2c.5-.5 1.1-.8 1.8-.8 1.4 0 2.6 1.2 2.6 2.6v162.2c0 7.2 5.9 13.1 13.1 13.1 7.2 0 13.1-5.9 13.1-13.1v-91.6c0-1.4 1.2-2.6 2.6-2.6s2.6 1.2 2.6 2.6v91.6c0 7.2 5.9 13.1 13.1 13.1s13.1-5.9 13.1-13.1V283.8c3.3 1.3 7.2.7 9.9-2l29.6-29.6c3.7-3.6 3.7-9.4.2-13zm-39.5 26.5v-33.8c0-1.4 1.2-2.6 2.6-2.6.7 0 1.4.3 1.8.8l15.6 15.6-20 20zm-139.3 14c8.6 0 15.6 7 15.6 15.6s-7 15.6-15.6 15.6-15.6-7-15.6-15.6 7-15.6 15.6-15.6zm16.3 35.1h-32.6c-8.2.4-14.8 7.2-14.8 15.5v21.5c.1 3.2 2.7 5.7 5.8 5.7s5.8-2.6 5.8-5.7v-18c0-1.1.9-1.9 2-1.9s1.9.9 1.9 1.9v63.6c0 3.8 3 6.8 6.8 6.8s6.8-3 6.8-6.8v-32.1c0-1.1.9-1.9 1.9-1.9 1.1 0 1.9.9 1.9 1.9v32.1c0 3.8 3 6.8 6.8 6.8s6.8-3 6.8-6.8v-63.6c0-1.1.8-1.9 1.9-1.9s2 .9 2 1.9v18c.1 3.2 2.7 5.7 5.8 5.7 3.1 0 5.8-2.6 5.8-5.7v-21.5c.2-8.3-6.3-15.1-14.6-15.5z" />
  </svg>
);
export default Parent;

