function AttendanceEdit({ width = 32, height = 32 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 577.82 544.06">
      <path d="m149.29,265.36c23.8,70.31,65.05,88.74,118.46,51.57-22.1,45.39-44.21,90.78-67.39,138.38,52.25,13.82,101.97,28.77,143.82,75.11-40.75,4.98-75.52,11.3-110.51,13.06-71.55,3.59-140.86-9.76-208.7-32.29-18.81-6.25-27.37-17.8-24.4-36.56,6.43-40.58,12.86-81.22,21.35-121.39,6.33-29.93,27.27-47.2,55.91-57.35,23.98-8.5,46.93-19.92,71.45-30.52Z" />
      <path d="m214.76,0c16.01,6.23,45.6,15.42,72.88,29.18,19.25,9.72,30.19,28.67,31.32,51.6,2.39,48.32,1.62,96.41-23.02,139.9-9.04,15.97-20.54,31.63-34.22,43.67-31.87,28.06-68.37,27.6-97.96-2.96-35-36.15-55.63-79.16-47.56-131.88,1.01-6.61-1.37-13.75-2.25-20.64C106.86,53.15,148.7.41,214.76,0Z" />
      <path
        d="m397.71,537.99c-33.42-58.31-82.62-91.62-143.61-111.51,15.33-31.38,29.5-60.4,46.71-95.63,33.44,29.88,64.77,57.86,97.4,87.01,52.93-55.73,104.32-117.29,179.61-150.01-83.84,72.28-130.73,169.17-180.11,270.14Z"
        fill="#3aaa35"
      />
      <path d="m278.47,265.65c27.07,11.64,53.6,23.72,80.66,34.47,22.57,8.97,37.79,24.24,44.09,47.46,1.53,5.66-1.3,12.5-2.35,20.57-40.12-29.97-76.52-57.15-113.66-84.89-5.85,7.66-9.43,12.35-13.95,18.27,1.98-13.64,3.72-25.63,5.21-35.88Z" />
    </svg>
  );
}

export default AttendanceEdit;
