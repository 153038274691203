import * as React from "react";
const UserSchedule = (props) => (
  <svg
    id="prefix__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 464.3 512"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".prefix__st0{fill-rule:evenodd;clip-rule:evenodd;stroke:#fff;stroke-width:9;stroke-miterlimit:10}"
      }
    </style>
    <g id="prefix___2">
      <path d="M95.4 222.6h50.9v50.9H95.4zM318 222.6h50.9v50.9H318zM243.8 222.6h50.9v50.9h-50.9zM95.4 302.1h50.9V353H95.4zM243.8 302.1h50.9V353h-50.9zM169.6 302.1h50.9V353h-50.9zM243.8 381.6h50.9v50.9h-50.9zM169.6 381.6h50.9v50.9h-50.9z" />
      <path d="M415.4 55H374v39.7c0 14.8-12.2 26.8-27.2 26.8h-54c-15 0-27.2-12-27.2-26.8V55h-67.1v39.3c0 15-12.3 27.2-27.5 27.2h-53.4c-15.2 0-27.5-12.2-27.5-27.2V55H48.8C21.9 55 0 76.7 0 103.4v360.2C0 490.3 21.9 512 48.8 512h366.6c27 0 48.9-21.7 48.9-48.4V103.4c0-26.7-21.9-48.4-48.9-48.4zm13.9 407.7c0 8.6-5.8 14.3-14.3 14.3H49.3c-8.6 0-14.3-5.8-14.3-14.3V178.1h394.3v284.6z" />
      <path d="M117.7 102.5h53.4c4.5 0 8.2-3.7 8.2-8.1V20.5C179.3 9.2 170 0 158.6 0h-28.5c-11.4 0-20.7 9.2-20.7 20.5v73.9c.1 4.4 3.8 8.1 8.3 8.1zM292.8 102.5h54c4.4 0 7.9-3.5 7.9-7.8V20.2c0-11.1-9.1-20.2-20.4-20.2h-29.1c-11.2 0-20.4 9.1-20.4 20.2v74.5c.1 4.3 3.7 7.8 8 7.8z" />
    </g>
    <g id="prefix__Layer_2_00000000909448979971977900000008834955294974948250_">
      <path
        className="prefix__st0"
        d="M269.7 330c0-31.2 25.3-56.5 56.5-56.5s56.5 25.3 56.5 56.5-25.3 56.5-56.5 56.5-56.5-25.4-56.5-56.5zM265.2 407.7c15.5-6.7 35.7-8.8 60.9-8.8 25.2 0 45.5 2.1 61 8.8 16.8 7.3 27.1 19.8 32.4 37.4 2.5 8.3-3.7 16.5-12.2 16.5H245c-8.6 0-14.8-8.3-12.3-16.5 5.4-17.6 15.6-30.1 32.5-37.4z"
      />
    </g>
  </svg>
);
export default UserSchedule;

