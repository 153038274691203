import { useField, useFormikContext } from "formik";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import he from "date-fns/locale/he";

export default function FormDatePicker({ ...props }) {
  const [field, meta] = useField(props.name);
  const { value } = meta;
  const { setFieldValue } = useFormikContext();
  registerLocale("he", he); // register it with the name you want
  setDefaultLocale("he");

  const localSetValue = (n, v) => {
    setFieldValue(n, v);
    props.onChange({ target: { name: n, value: v } });
  };

  return (
    <div className="picker_with_label">
      <div className="picker_label">{props.label}</div>
      <div className="picker_picker">
        <DatePicker
          {...field}
          selected={value}
          dateFormat="eee d MMM, yy"
          locale="he"
          onChange={(date) => localSetValue(field.name, date)}
        />
      </div>
    </div>
  );
}
