import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import StudentHome from "./student_home";
import Event from "../../components/Event/event";
import { getCurTeacherLessons } from "../../data_access/lesson";

export default function Home() {
  const user = useSelector((state) => state.user);
  const [lessons, setLessons] = useState([]);
  const [view, setView] = useState(
    sessionStorage.getItem("curCalendarView")
      ? sessionStorage.getItem("curCalendarView")
      : Views.DAY
  );

  const [date, setDate] = useState(
    sessionStorage.getItem("curCalendarDate")
      ? new Date(sessionStorage.getItem("curCalendarDate"))
      : new Date()
  );

  const getAllLessons = useCallback(async () => {
    if (user.role === "student" || user.role === "parent") {
      return;
    }
    try {
      const data = await getCurTeacherLessons(user.token);
      const mapped = data.map((lesson) => {
        return {
          start: new Date(lesson.start_time),
          end: new Date(lesson.end_time),
          title: lesson.title,
          canceled: lesson.canceled,
          canceled_reason: lesson.canceled_reason,
          students: lesson.students,
          id: lesson._id._id,
        };
      });
      setLessons(mapped);
    } catch (error) {
      console.log(error);
      setLessons([]);
    }
  }, [user.role, user.token]);

  useEffect(() => {
    getAllLessons();
  }, [getAllLessons]);

  const navigate = useNavigate();

  const handleSelectEvent = useCallback(
    (event) => {
      navigate(`/lesson/${event.id}`);
    },
    [navigate]
  );

  const onNavigate = useCallback(
    (newDate) => {
      sessionStorage.setItem("curCalendarDate", newDate.toISOString());
      setDate(newDate);
    },
    [setDate]
  );

  const onView = useCallback(
    (newView) => {
      sessionStorage.setItem("curCalendarView", newView);
      setView(newView);
    },
    [setView]
  );

  const { components } = useMemo(
    () => ({
      components: {
        event: Event,
      },
    }),
    []
  );

  const localizer = momentLocalizer(moment);
  const culture = "he";
  const messages = {
    week: "שבוע",
    work_week: "שבוע עבודה",
    day: "יום",
    month: "חודש",
    previous: "<<",
    next: ">>",
    today: "היום",
    agenda: "יומן",

    showMore: (total) => `עוד+${total}`,
  };

  if (user.role && (user.role === "student" || user.role === "parent")) {
    return (
      <div className="home">
        <Header page="home" />
        <SubHeader />
        <div className="home_middle">
          <StudentHome />
        </div>
      </div>
    );
  }

  return (
    <div className="home">
      <Header page="home" />
      <SubHeader />
      <div className="home_middle">
        {user.role && (user.role === "teacher" || user.role === "admin") && (
          <div className="report_presence">
            <div className="report_presence_header">דף דיווח נוכחות</div>
            <Calendar
              localizer={localizer}
              culture={culture}
              components={components}
              rtl={true}
              min={new Date(0, 0, 0, 9, 0, 0)}
              max={new Date(0, 0, 0, 19, 0, 0)}
              events={lessons}
              date={date}
              view={view}
              onView={onView}
              onNavigate={onNavigate}
              onSelectEvent={handleSelectEvent}
              selectable
              messages={messages}
              style={{ height: 800 }}
              views={{
                day: true,
                week: true,
                month: true,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
