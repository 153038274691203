import * as React from "react";
const Teacher = (props) => (
  <svg
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 511.999 511.999"
    {...props}
  >
    <path d="M302.195 11.833H13.049C5.842 11.833 0 17.675 0 24.882v214.289c0 7.207 5.842 13.049 13.049 13.049h283.839l-34.352-21.329a36.49 36.49 0 01-6.109-4.768H26.097V37.93h263.049v126.703c4.01.847 7.943 2.39 11.625 4.677l14.473 8.986V24.882c0-7.207-5.842-13.049-13.049-13.049z" />
    <path d="M216.857 134.337c-4.352-3.43-10.665-2.685-14.097 1.668-3.432 4.353-2.686 10.665 1.668 14.097l44.279 34.914a36.654 36.654 0 012.156-4.034 36.098 36.098 0 0110.94-11.206l-44.946-35.439z" />
    <circle cx={419.71} cy={81.405} r={37.557} />
    <path d="M511.33 173.609c-.118-23.528-19.355-42.67-42.884-42.67H450.26l-22.507 58.37 4.709-23.724a7.558 7.558 0 00-.79-5.113l-7.381-13.424 6.551-11.914a2.665 2.665 0 00-2.334-3.948h-17.96a2.664 2.664 0 00-2.334 3.948l6.537 11.889-7.367 13.4a7.557 7.557 0 00-.77 5.211l5.438 23.675c-3.119-8.087-21.08-52.728-23.255-58.37h-17.83c-23.529 0-42.766 19.141-42.884 42.67l-.17 33.884-36.702-22.787c-8.501-5.28-19.674-2.667-24.953 5.836s-2.666 19.675 5.836 24.954l64.219 39.873c12.028 7.47 27.609-1.167 27.68-15.304l.334-66.275a3.79 3.79 0 017.584.025c-.018 87.323-.394 111.735-.394 304.606 0 12.01 9.736 21.746 21.746 21.746s21.746-9.736 21.746-21.746V304.604h9.388v173.817c0 12.01 9.736 21.746 21.746 21.746s21.746-9.736 21.746-21.746l.008-304.612a3.596 3.596 0 017.191-.018l.669 133.132c.05 9.977 8.154 18.03 18.119 18.03h.094c10.007-.05 18.081-8.205 18.03-18.212l-.67-133.132z" />
  </svg>
);
export default Teacher;

