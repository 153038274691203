import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import Presence from "../../components/presence";
import "./style.css";
import { getStudents } from "../../data_access/user";
import {
  deleteLesson,
  deletePresenceForLesson,
  getLesson,
  getPresenceForLesson,
  updateLesson,
  updatePresenceForLesson,
} from "../../data_access/lesson";
import Edit from "../../svg/edit";

export default function EditLesson({ mode }) {
  const user = useSelector((state) => state.user);
  const [curStudent, setCurStudent] = useState(0);
  const [lessonInfo, setLessonInfo] = useState({
    title: "UNDEFINED",
    start: new Date(),
    canceled: false,
  });
  const [updatePresence, setUpdatePresence] = useState(false);
  const [cancelReasonVisible, setCancelReasonVisible] = useState(false);
  const canceledReasonText = useRef("");
  const { lesson_id } = useParams();
  const [students, setStudents] = useState([]);
  const [presence, setPresence] = useState([]);
  const [presenceToUpdate, setPresenceToUpdate] = useState({});
  const dateFormatOptions = {
    weekday: "narrow",
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const navigate = useNavigate();

  const getLessonWrapped = useCallback(async () => {
    try {
      const data = await getLesson(user.token, lesson_id);
      if (data) {
        const teacher_name = data[0].teacher_id
          ? data[0].teacher_id.first_name
          : "מורה לא תקין";
        setLessonInfo({
          title: data[0].title,
          start: new Date(data[0].start_time),
          teacher_fname: teacher_name,
          canceled: data[0].canceled,
          canceled_reason: data[0].canceled_reason,
        });
      } else {
        setLessonInfo({ title: "MISSING", start: new Date(), canceled: false });
      }
    } catch (error) {
      console.log(error);
      setLessonInfo({ title: "ERR", start: new Date(), canceled: false });
    }
  }, [lesson_id, user.token]);

  const getStudentsWrapped = useCallback(async () => {
    try {
      const data = await getStudents(user.token);
      setStudents(data);
      setCurStudent(data[0]._id);
    } catch (error) {
      console.log(error);
    }
  }, [user.token]);

  const getPresence = useCallback(async () => {
    try {
      const data = await getPresenceForLesson(user.token, lesson_id);
      setPresence(data);
    } catch (error) {
      console.log(error);
      setPresence([]);
    }
  }, [lesson_id, user.token]);

  const addPresence = async () => {
    const found = presence.find(
      (p) => p.student === curStudent || p.student._id === curStudent
    );
    if (found) {
      alert("התלמיד כבר רשום לשיעור זה");
      return;
    }
    setPresenceToUpdate({
      lesson: lesson_id,
      student: curStudent,
      mode: "add",
    });
    setUpdatePresence(true);
  };

  const updateLessonTitle = useCallback(
    async (title) => {
      try {
        await updateLesson(user.token, lesson_id, title);
        setLessonInfo({ ...lessonInfo, title });
      } catch (error) {
        console.log(error);
      }
    },
    [lessonInfo, lesson_id, user.token]
  );

  const updateLessonCanceled = useCallback(
    async (canceled = false, canceled_reason = "") => {
      try {
        await updateLesson(
          user.token,
          lesson_id,
          null,
          null,
          null,
          canceled,
          canceled_reason
        );
        setLessonInfo({ ...lessonInfo, canceled, canceled_reason });
      } catch (error) {
        console.log(error);
      }
    },
    [lessonInfo, lesson_id, user.token]
  );

  const deleteLessonWrapped = async () => {
    if (
      window.confirm(`אתה בטוח שברצונך למחוק את השיעור ${lessonInfo.title}`)
    ) {
      try {
        await deleteLesson(user.token, lesson_id);
        navigate("/schedule");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateLessonPresence = useCallback(async () => {
    try {
      await updatePresenceForLesson(user.token, lesson_id, presenceToUpdate);
      setUpdatePresence(false);
      getPresence();
    } catch (error) {
      window.alert(error.response.data.message);
      console.log(error);
    }
  }, [lesson_id, user.token, presenceToUpdate, getPresence]);

  useEffect(() => {
    getLessonWrapped();
    if (mode === "admin") {
      getStudentsWrapped();
    }
    getPresence();
  }, [getLessonWrapped, getPresence, getStudentsWrapped, mode]);

  useEffect(() => {
    if (updatePresence) updateLessonPresence();
  }, [updatePresence, updateLessonPresence]);

  const changeStudent = (event) => {
    setCurStudent(event.target.value);
  };

  const editTitle = () => {
    const newTitle = window.prompt("שם השיעור:", lessonInfo.title);
    if (newTitle) updateLessonTitle(newTitle);
  };

  const uncancelLesson = () => {
    updateLessonCanceled(false, "");
  };

  const cancelLessonClicked = () => {
    setCancelReasonVisible(true);
  };

  const actuallyCancelLesson = () => {
    updateLessonCanceled(true, canceledReasonText.current);
  };

  const handlePresenceChange = async (student_id, attended, late, early) => {
    let presence = "no";
    if (attended) presence = "yes";
    if (late || early) presence = "partial";

    setPresenceToUpdate({
      lesson: lesson_id,
      student: student_id,
      present: presence,
      attended,
      was_late: late,
      left_early: early,
      mode: "update",
    });
    setUpdatePresence(true);
  };

  const deletePresence = async (student_id, full_name) => {
    if (window.confirm(`אתה בטוח שברצונך למחוק את התלמיד ${full_name}`)) {
      try {
        await deletePresenceForLesson(user.token, lesson_id, student_id);
        await getPresence();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const UnCancelLessonButton = () => {
    return (
      <div>
        <div>
          <span>השיעור בוטל מהסיבה הבאה:</span>
          <br />
          <span>{lessonInfo.canceled_reason}</span>
        </div>
        <button
          className="normal_btn"
          onClick={() => {
            uncancelLesson();
          }}
        >
          החזר את השיעור למערכת
        </button>
      </div>
    );
  };

  const CancelLessonWithReason = () => {
    if (!cancelReasonVisible)
      return (
        <div>
          <button
            className="normal_btn"
            onClick={() => {
              cancelLessonClicked();
            }}
          >
            בטל את השיעור
          </button>
        </div>
      );
    return (
      <div className="editCancelReason">
        <div>סיבת ביטול:</div>
        <input
          type="text"
          ref={canceledReasonText}
          // value={canceledReasonText?.current}
          onChange={(e) => {
            canceledReasonText.current = e.target.value;
          }}
        />
        <button
          className="normal_btn"
          onClick={() => {
            actuallyCancelLesson();
          }}
        >
          כן, בטל
        </button>
      </div> //editCancelReason
    );
  };

  return (
    <div className="lesson">
      <Header page="lesson" />
      <SubHeader />
      <div className="lesson_wrap">
        <div
          className="lesson_title"
          dir="rtl"
          onClick={() => {
            editTitle();
          }}
        >
          <div className="lesson_title_edit">
            <Edit width="24" height="24"></Edit>
            <div>{lessonInfo.title}</div>
          </div>
          <div className="lesson_date">
            {lessonInfo.start.toLocaleString("he", dateFormatOptions)}
          </div>
        </div>
        <div className="cancelLessonSection">
          {lessonInfo.canceled ? (
            <UnCancelLessonButton />
          ) : (
            <CancelLessonWithReason />
          )}
        </div>
        <div className="lesson_teacher_name">{lessonInfo.teacher_fname}</div>
        <div key="entrs" className="presence_entries">
          {presence.map((entry) => {
            if (entry.student)
              return (
                <Presence
                  key={entry.student._id}
                  lesson_id={lesson_id}
                  deletePresence={deletePresence}
                  handlePresenceChanged={handlePresenceChange}
                  presenceRecord={entry}
                  mode={mode}
                />
              );
            return null;
          })}
        </div>
        {mode === "teacher" && (
          <div className="form_footer">
            <button
              className="main_btn"
              onClick={() => {
                navigate("/");
              }}
            >
              חזור
            </button>
          </div>
        )}
        {mode === "admin" && (
          <div className="form_footer">
            <select value={curStudent} onChange={changeStudent}>
              {students.map((s) => (
                <option key={s._id} value={s._id}>
                  {s.first_name}&nbsp;{s.last_name}
                </option>
              ))}
            </select>
            <button
              className="main_btn"
              onClick={() => {
                addPresence();
              }}
            >
              הוספת תלמיד
            </button>
            <button
              className="normal_btn"
              onClick={() => {
                navigate("/schedule");
              }}
            >
              חזור
            </button>
            <button
              className="red_btn"
              onClick={() => {
                deleteLessonWrapped();
              }}
            >
              מחק את השיעור
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
