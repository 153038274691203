import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import ListItem from "../../components/list_item";

import "./style.css";

import apiWrapper from "../../helpers/api_helper";

export default function CalendarList({ mode }) {
  const user = useSelector((state) => state.user);
  const [orgName, setOrgName] = useState("");
  const [year, setYear] = useState(2023);
  const origYearsToChooseFrom = useMemo(
    () => [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032],
    []
  );
  const [yearsToChooseFrom, setYearsToChooseFrom] = useState(
    origYearsToChooseFrom
  );
  const middle = useRef(null);
  const [height, setHeight] = useState();
  useEffect(() => {
    setHeight(middle.current.clientHeight);
  }, []);
  const [calendars, setCalendars] = useState([]);

  const navigate = useNavigate();

  const getCalendars = useCallback(async () => {
    try {
      const data = await apiWrapper.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/calendars?org_id=${user.org_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setCalendars(data);
      const existingYears = data.map((c) => c.year);
      const newYearsToChooseFrom = origYearsToChooseFrom.filter(
        (y) => !existingYears.includes(y)
      );
      setYearsToChooseFrom(newYearsToChooseFrom);
      setYear(newYearsToChooseFrom[0]);
    } catch (error) {
      console.log(error);
      setCalendars([]);
    }
  }, [origYearsToChooseFrom, user.org_id, user.token]);

  useEffect(() => {
    getCalendars();
  }, [getCalendars]);

  const deleteCalendar = async ({ itemKey }) => {
    if (
      window.confirm(`אתה בטוח שברצונך למחוק לוח חופשות  ${orgName} ${itemKey}`)
    ) {
      try {
        await apiWrapper.delete(
          `${process.env.REACT_APP_BACKEND_URL}/api/calendar`,
          {
            data: {
              org_id: user.org_id,
              year: itemKey,
            },
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        getCalendars();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const labelClick = async ({ itemKey }) => {
    navigate(`/calendar/${itemKey}`);
  };

  const addCalendar = async () => {
    await apiWrapper.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calendar`,
      {
        org_id: user.org_id,
        year: year,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    getCalendars();
  };

  const handleChange = (e) => {
    setYear(e.target.value);
  };

  return (
    <div className="calendar" style={{ height: `${height + 150}px` }}>
      <Header page="calendar" />
      <SubHeader />
      <div className="calendar_list_wrap">
        <div className="calendar_title" dir="rtl">
          <div>{orgName}</div>
        </div>
        <div key="entrs" className="calendar_entries" ref={middle}>
          {calendars.length === 0 ? (
            <span>לא הוגדר עדיין לוח חופשות</span>
          ) : (
            calendars.map((v) => {
              return (
                <ListItem
                  itemKey={v.year}
                  deleteItem={deleteCalendar}
                  labelClick={labelClick}
                  labels={[v.year]}
                />
              );
            })
          )}
        </div>
        <div className="form_footer">
          <select name="year" value={year} onChange={handleChange}>
            {yearsToChooseFrom.map((y) => (
              <option value={y} label={y}>
                {y}
              </option>
            ))}
          </select>
          <button
            className="main_btn calendar_wrap_button"
            onClick={() => {
              addCalendar();
            }}
          >
            הוספת לוח חופשות
          </button>
          {/* {error && <div className="error_text">{error}</div>} */}

          <button
            className="normal_btn calendar_wrap_button"
            onClick={() => {
              navigate("/admin");
            }}
          >
            חזור
          </button>
        </div>
      </div>
    </div>
  );
}
