import "./style.css";
import { useSelector } from "react-redux";

export default function SubHeader({ page, setVisible }) {
  const user = useSelector((state) => state.user);

  return (
    <div className="sub_header">
      <div className="sub_org">{user.org_name}</div>
      <div className="sub_ver">
        <span>v1.0&nbsp;&nbsp;&nbsp;(c) 2023 כל הזכויות שמורות לישראל רוט</span>
      </div>
    </div>
  );
}
