import "./style.css";
import { ErrorMessage, useField } from "formik";

export default function LoginInput({
  placeholder,
  bottom,
  inputmode,
  innerRef,
  ...props
}) {
  const [field, meta] = useField(props);
  return (
    <div className="input_wrap">
      {meta.touched && meta.error && !bottom && (
        <div className="input_error" style={{ transform: "translateY(10px)" }}>
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
          <div className="error_arrow_top"></div>
        </div>
      )}
      <input
        className={meta.touched && meta.error ? "input_error_border" : ""}
        type={field.type}
        name={field.name}
        placeholder={placeholder}
        inputMode={inputmode}
        ref={innerRef}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && bottom && (
        <i className="error_icon error_icon_bottom" />
      )}
      {meta.touched && meta.error && !bottom && (
        <i className="error_icon error_icon_top" />
      )}
      {meta.touched && meta.error && bottom && (
        <div className="input_error" style={{ transform: "translateY(-10px)" }}>
          <div className="error_arrow_bottom"></div>
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </div>
      )}
    </div>
  );
}
