import { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoginInput from "../../components/inputs/login";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
// import Vacation from "../../components/vacation";
import ListItem from "../../components/list_item";
import Checkbox from "../../components/inputs/checkbox";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormDatePicker from "../../components/inputs/datepicker";

import "./style.css";

import apiWrapper from "../../helpers/api_helper";

export default function WorkingCalendar() {
  const user = useSelector((state) => state.user);
  const { year } = useParams();
  const [calendarInfo, setCalendarInfo] = useState({
    restDOW: [],
    vacations: [],
  });
  const initialPickerDate = new Date();
  initialPickerDate.setMonth(initialPickerDate.getMonth() + 1);

  const daysOfWeek = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];

  const updateCalendarRecord = async (updatePayload) => {
    const data = await apiWrapper.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/calendar`,
      updatePayload,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return data;
  };

  const handleCheckboxChange = async (changeEvent) => {
    const { name } = changeEvent.target;
    const val = daysOfWeek.indexOf(name);
    const index = calendarInfo.restDOW.indexOf(val);
    var newRestDOW = calendarInfo.restDOW;
    if (index >= 0) {
      calendarInfo.restDOW.splice(index, 1);
    } else {
      newRestDOW = [...calendarInfo.restDOW, val];
    }
    await updateCalendarRecord({
      org_id: user.org_id,
      year: year,
      restDOW: newRestDOW,
    });

    getCalendar();
  };

  const [vacationInfo, setVacationInfo] = useState({
    name: "",
    start_date: initialPickerDate,
    end_date: initialPickerDate,
  });
  const middle = useRef(null);
  const [height, setHeight] = useState();
  useEffect(() => {
    setHeight(middle.current.clientHeight);
  }, []);
  const dateFormatOptions = {
    weekday: "narrow",
    month: "2-digit",
    day: "2-digit",
  };

  const getCalendar = useCallback(async () => {
    try {
      const data = await apiWrapper.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/calendar?org_id=${user.org_id}&year=${year}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setCalendarInfo(data[0]);
    } catch (error) {
      console.log(error);
    }
  }, [user.org_id, user.token, year]);

  const navigate = useNavigate();
  const vacationValidation = Yup.object({
    name: Yup.string().required("vacation name is required"),
    start_date: Yup.date().required("start date  is required"),
    end_date: Yup.date().required("end date  is required"),
  });

  const labelClick = async ({ itemKey }) => {
    console.log(itemKey);
  };

  const deleteCalendar = async () => {
    if (
      window.confirm(
        `אתה בטוח שברצונך למחוק לוח חופשות  ${user.org_name} ${year}`
      )
    ) {
      try {
        await apiWrapper.delete(
          `${process.env.REACT_APP_BACKEND_URL}/api/calendar`,
          {
            data: {
              lesson: calendarInfo.id,
            },
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        navigate("/admin");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const addVacation = async () => {
    await updateCalendarRecord({
      org_id: user.org_id,
      year: year,
      vacations: [...calendarInfo.vacations, vacationInfo],
    });

    getCalendar();
  };

  const deleteVacation = async ({ itemKey }) => {
    if (window.confirm(`אתה בטוח שברצונך למחוק את חופשת  ${itemKey}`)) {
      const newVacations = calendarInfo.vacations.filter(function (el) {
        return el.name !== itemKey;
      });
      await updateCalendarRecord({
        org_id: user.org_id,
        year: year,
        vacations: newVacations,
      });

      getCalendar();
    }
  };

  const handleVacationChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "start_date" && value > vacationInfo.end_date) ||
      (name === "end_date" && value < vacationInfo.start_date)
    ) {
      setVacationInfo({ ...vacationInfo, start_date: value, end_date: value });
      return;
    }
    setVacationInfo({ ...vacationInfo, [name]: value });
  };

  useEffect(() => {
    getCalendar();
  }, [getCalendar]);

  return (
    <div className="calendar" style={{ height: `${height + 150}px` }}>
      <Header page="calendar" />
      <SubHeader />
      <div className="calendar_wrap">
        <div className="calendar_title" dir="rtl">
          <div>{user.org_name}</div>
          <div>{year}</div>
        </div>
        <div className="dow_title">חופשות במהלך שנה זו</div>
        <div key="entrs" className="vacation_entries" ref={middle}>
          {calendarInfo.vacations.length === 0 ? (
            <span>לא הוגדרו חופשות</span>
          ) : (
            calendarInfo.vacations.map((v) => {
              const sd = new Date(v.start_date);
              const ed = new Date(v.end_date);
              return (
                <ListItem
                  key={v.name}
                  itemKey={v.name}
                  deleteItem={deleteVacation}
                  labelClick={labelClick}
                  labels={[
                    v.name,
                    `${sd.toLocaleDateString(
                      "he",
                      dateFormatOptions
                    )} - ${ed.toLocaleDateString("he", dateFormatOptions)}`,
                  ]}
                />
              );
            })
          )}
        </div>
        <div className="form_footer">
          <div className="dow_title">ימות שבוע ללא לימודים</div>
          <div className="dow_checkboxes">
            {daysOfWeek.map((v, i) => (
              <Checkbox
                label={v}
                isSelected={calendarInfo.restDOW.includes(i)}
                onCheckboxChange={handleCheckboxChange}
                key={i}
              />
            ))}
          </div>
          <div className="dow_title">הגדרת חופשה חדשה</div>
          <Formik
            enableReinitialize
            validationSchema={vacationValidation}
            initialValues={{
              name: vacationInfo.name,
              start_date: vacationInfo.start_date,
              end_date: vacationInfo.end_date,
            }}
            onSubmit={() => {
              addVacation();
            }}
          >
            {(formik) => (
              <Form>
                <LoginInput
                  type="text"
                  name="name"
                  placeholder="שם החופשה"
                  onChange={handleVacationChange}
                />
                <FormDatePicker
                  name="start_date"
                  label="תאריך התחלה:"
                  onChange={handleVacationChange}
                />
                <FormDatePicker
                  name="end_date"
                  label="תאריך סיום:"
                  onChange={handleVacationChange}
                />
                <button type="submit" className="main_btn calendar_wrap_button">
                  הוספת חופשה
                </button>
                {/* {error && <div className="error_text">{error}</div>} */}
              </Form>
            )}
          </Formik>

          <button
            className="normal_btn calendar_wrap_button"
            onClick={() => {
              navigate("/calendars");
            }}
          >
            חזור
          </button>
          <button
            className="red_btn calendar_wrap_button"
            onClick={() => {
              deleteCalendar();
            }}
          >
            מחק את לוח החופשות
          </button>
        </div>
      </div>
    </div>
  );
}
