import * as React from "react";
const Admin = (props) => (
  <svg
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 474.565 474.565"
    {...props}
  >
    <path d="M255.204 102.3c-.606-11.321-12.176-9.395-23.465-9.395 8.339 2.221 16.228 5.311 23.465 9.395zM134.524 73.928c-43.825 0-63.997 55.471-28.963 83.37 11.943-31.89 35.718-54.788 66.886-63.826-8.526-11.787-22.301-19.544-37.923-19.544z" />
    <path d="M43.987 148.617a97.207 97.207 0 006.849 16.438L36.44 179.459c-3.866 3.866-3.866 10.141 0 14.015l25.375 25.383a9.93 9.93 0 007.019 2.888c2.61 0 5.125-1.04 7.005-2.888l14.38-14.404c2.158 1.142 4.55 1.842 6.785 2.827 0-.164-.016-.334-.016-.498 0-11.771 1.352-22.875 3.759-33.302C83.385 162.306 71.8 142.91 71.8 120.765c0-34.592 28.139-62.739 62.723-62.739 23.418 0 43.637 13.037 54.43 32.084 11.523-1.429 22.347-1.429 35.376 1.033-1.676-5.07-3.648-10.032-6.118-14.683l14.396-14.411a9.839 9.839 0 002.918-7.004 9.837 9.837 0 00-2.918-7.004l-25.361-25.367a9.863 9.863 0 00-7.003-2.904 9.806 9.806 0 00-6.989 2.904l-14.442 14.411c-5.217-2.764-10.699-5.078-16.444-6.825V9.9c0-5.466-4.411-9.9-9.893-9.9h-35.888c-5.451 0-9.909 4.434-9.909 9.9v20.359c-5.73 1.747-11.213 4.061-16.446 6.825L75.839 22.689a9.869 9.869 0 00-7.005-2.904 9.864 9.864 0 00-7.003 2.896L36.44 48.048a9.952 9.952 0 00-2.888 7.012 9.921 9.921 0 002.888 7.004l14.396 14.403c-2.75 5.218-5.063 10.708-6.817 16.438H23.675c-5.482 0-9.909 4.441-9.909 9.915v35.889c0 5.458 4.427 9.908 9.909 9.908h20.312zM354.871 340.654c15.872-8.705 26.773-25.367 26.773-44.703 0-28.217-22.967-51.168-51.184-51.168-9.923 0-19.118 2.966-26.975 7.873-4.705 18.728-12.113 36.642-21.803 52.202a153.3 153.3 0 0173.189 35.796z" />
    <path d="M460.782 276.588c0-5.909-4.799-10.693-10.685-10.693H428.14c-1.896-6.189-4.411-12.121-7.393-17.75l15.544-15.544a10.624 10.624 0 003.137-7.555c0-2.835-1.118-5.553-3.137-7.563l-27.363-27.371a10.651 10.651 0 00-7.561-3.138 10.616 10.616 0 00-7.547 3.138l-15.576 15.552c-5.623-2.982-11.539-5.481-17.751-7.369v-21.958c0-5.901-4.768-10.685-10.669-10.685H311.11c-2.594 0-4.877 1.04-6.739 2.578 3.26 11.895 5.046 24.793 5.046 38.552 0 8.735-.682 17.604-1.956 26.423 7.205-2.656 14.876-4.324 22.999-4.324 36.99 0 67.086 30.089 67.086 67.07 0 23.637-12.345 44.353-30.872 56.303a152.948 152.948 0 0131.168 51.976c1.148.396 2.344.684 3.54.684 2.733 0 5.467-1.04 7.563-3.13l27.379-27.371c2.004-2.004 3.106-4.721 3.106-7.555s-1.102-5.551-3.106-7.563l-15.576-15.552c2.982-5.621 5.497-11.555 7.393-17.75h21.957c2.826 0 5.575-1.118 7.563-3.138a10.676 10.676 0 003.138-7.555l-.017-38.712zM376.038 413.906c-16.602-48.848-60.471-82.445-111.113-87.018-16.958 17.958-37.954 29.351-61.731 29.351-23.759 0-44.771-11.392-61.713-29.351-50.672 4.573-94.543 38.17-111.145 87.026l-9.177 27.013c-2.625 7.773-1.368 16.338 3.416 23.007a25.462 25.462 0 0020.685 10.631h315.853c8.215 0 15.918-3.96 20.702-10.631a25.476 25.476 0 003.4-23.007l-9.177-27.021z" />
    <path d="M120.842 206.782c0 60.589 36.883 125.603 82.352 125.603 45.487 0 82.368-65.014 82.368-125.603.001-125.594-164.72-125.843-164.72 0z" />
  </svg>
);
export default Admin;

