import React from "react";
import "./style.css";

export default function Checkbox({
  label,
  isSelected,
  onCheckboxChange,
  ...props
}) {
  let cls = "form-check";
  if (props.disabled) {
    cls = "form-check-dis";
  }
  return (
    <div className={cls}>
      <label>
        <input
          type="checkbox"
          name={label}
          checked={isSelected}
          onChange={onCheckboxChange}
          className="form-check-input"
          {...props}
        />
        {label}
      </label>
    </div>
  );
}
