import "./style.css";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import * as SVGIcons from "../../svg/AdminIcons";
import { createElement } from "react";

const LinkTile = ({ label, to, icon = "Teacher" }) => {
  return (
    <Link to={to}>
      <div className="linkTile">
        <div className="linkTileIcon">
          {createElement(SVGIcons[icon], {
            fontSize: "3em",
          })}
        </div>
        <div className="linkTileText">{label}</div>
      </div>
    </Link>
  );
};

export default function Admin({ user }) {
  return (
    <div className="admin">
      <Header page="lesson" />
      <SubHeader />
      <div className="links_grid">
        <LinkTile to="/users/teacher" label="ניהול מורים" icon="Teacher" />
        <LinkTile to="/users/student" label="ניהול תלמידים" icon="Student" />
        <LinkTile to="/users/admin" label="ניהול אדמינים" icon="Admin" />
        <LinkTile to="/users/parent" label="ניהול הורים" icon="Parent" />
        <LinkTile to="/schedule" label="מערכת שעות" icon="Schedule" />
        <LinkTile to="/r_schedule" label="תצוגת מערכת" icon="UserSchedule" />
        <LinkTile to="/reports" label="דוחות" icon="Reports" />

        {/* <Link to="/users/student">ניהול תלמידים</Link>
        <Link to="/users/admin">ניהול אדמינים</Link>
        <Link to="/users/parent">ניהול הורים</Link>
        <Link to="/schedule">ניהול מערכת שעות</Link> */}
        {/* <Link to="/calendars">ניהול לוח חופשות</Link> */}
      </div>
    </div>
  );
}
