export const dateRangeFromCalendar = (calendarRange) => {
  if (calendarRange instanceof Array) {
    if (calendarRange.length === 1) {
      return {
        start: calendarRange[0],
        end: calendarRange[0],
      };
    } else {
      const last = calendarRange[calendarRange.length - 1];
      last.setDate(last.getDate() + 1);
      return {
        start: calendarRange[0],
        end: last,
      };
    }
  } else {
    return calendarRange;
  }
};
