import React, { useEffect, useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginInput from "../inputs/login";
import GridLoader from "react-spinners/GridLoader";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import apiWrapper from "../../helpers/api_helper";

const loginInfo = {
  mobile: "",
  pincode: "",
};

export default function LoginForm() {
  const [login, setLogin] = useState(loginInfo);
  const [pincode_visible, setPinCodeVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mobile, pincode } = login;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };
  const pincodeInputRef = useRef(null);

  const loginValidation = Yup.object({
    mobile: Yup.string()
      .required("Mobile is required")
      .matches(/^05\d\d\d\d\d\d\d\d/, "please provide a valid phone number"),
    password: Yup.string().matches(
      /^\d\d\d\d\d\d/,
      "please enter 6 digits pin code"
    ),
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const submitLoginForm = async () => {
    try {
      setLoading(true);
      if (pincode_visible) {
        const data = await apiWrapper.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/login`,
          {
            mobile,
            pin_code: pincode,
          }
        );
        setError("");
        setLoading(false);
        dispatch({ type: "LOGIN", payload: data });
        Cookies.set("user", JSON.stringify(data), { expires: 90 });
        console.log(data);
        sessionStorage.clear();
        if (data.role === "admin") navigate("/admin");
        else navigate("/");
      } else {
        await apiWrapper.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/pre_login`,
          {
            mobile,
          }
        );
        setPinCodeVisible(true);
        setError("");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  useEffect(() => {
    if (pincodeInputRef.current) {
      pincodeInputRef.current.focus();
    }
  }, [pincode_visible]);
  return (
    <div>
      <div className="login_1">
        <span>קידום נוער - מערכת נוכחות</span>
      </div>
      <div className="logo_div">
        <img
          src="/images/kidum_owl.png"
          width={320}
          height={320}
          alt="kidum owl logo"
        />
        {/* <Kidum width="320" height="320" /> */}
      </div>
      <div className="login_2">
        <div className="login_2_wrap">
          <Formik
            enableReinitialize
            validationSchema={loginValidation}
            initialValues={{
              mobile,
              pincode,
            }}
            onSubmit={() => {
              submitLoginForm();
            }}
          >
            {(formik) => (
              <Form>
                <LoginInput
                  type="number"
                  name="mobile"
                  inputmode="numeric"
                  placeholder="טלפון נייד"
                  onChange={handleChange}
                />
                {pincode_visible && (
                  <LoginInput
                    type="number"
                    name="pincode"
                    inputmode="numeric"
                    placeholder="קוד 6 ספרות"
                    autoComplete="one-time-code"
                    pattern="\d{6}"
                    onChange={handleChange}
                    bottom
                    innerRef={pincodeInputRef}
                  />
                )}
                <GridLoader
                  color="#F7931E"
                  loading={loading}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <button type="submit" className="main_btn">
                  Log In
                </button>
                {error && <div className="error_text">{error}</div>}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="login_footer_wrap">
        <span>v1.0&nbsp;&nbsp;&nbsp;(c) 2023 כל הזכויות שמורות לישראל רוט</span>
      </div>
    </div>
  );
}
