import { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Home from "./pages/home";
import LoggedInRoutes from "./routes/LoggedInRoutes";
import NotLoggedInRoutes from "./routes/NotLoggedInRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import Cookies from "js-cookie";
import Admin from "./pages/admin";
import EditLesson from "./pages/edit_lesson";
import Schedule from "./pages/schedule";
import Attendance from "./pages/attendance";
import Users from "./pages/admin/users";
import AddUser from "./pages/admin/add_user";
import WorkingCalendar from "./pages/working_calendar";
import CalendarList from "./pages/calendar_list";
import eventBus from "./helpers/eventBus";
import Reports from "./pages/reports";
import PerStudentReport from "./pages/reports/per_student";
import PerSubjectReport from "./pages/reports/per_subject";
import StudentHome from "./pages/home/student_home";
import ResSchedule from "./pages/schedule/res_schedulr";
import NotFound from "./pages/not_found";
import PerStudentPDF from "./pages/reports/per_student_pdf";
import PerSubjectPDF from "./pages/reports/per_subject_pdf";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      user: undefined,
    };
  }

  componentDidMount() {
    eventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    eventBus.remove("logout");
  }

  logOut = () => {
    window.alert("logout");
    Cookies.set("user", "", { expires: 90 });
    window.location.reload();
  };

  render() {
    const { user } = this.state;
    return (
      <div>
        <Routes>
          <Route element={<AdminRoutes />}>
            <Route path="/admin" element={<Admin user={user} />} exact />
            <Route
              path="/admin_lesson/:lesson_id"
              element={<EditLesson mode="admin" user={user} />}
              exact
            />
            <Route path="/reports" element={<Reports user={user} />} exact />
            <Route
              path="/per_subject_report"
              element={<PerSubjectReport user={user} />}
              exact
            />
            <Route
              path="/per_student_report"
              element={<PerStudentReport user={user} />}
              exact
            />
            <Route
              path="/per_student_pdf/:id/:start/:end"
              element={<PerStudentPDF user={user} />}
              exact
            />
            <Route
              path="/per_subject_pdf/:subj/:start/:end"
              element={<PerSubjectPDF user={user} />}
              exact
            />
            <Route path="/schedule" element={<Schedule user={user} />} exact />
            <Route
              path="/r_schedule"
              element={<ResSchedule user={user} />}
              exact
            />
            <Route path="/as/:uid" element={<StudentHome />} exact />
            <Route
              path="/calendars"
              element={<CalendarList user={user} />}
              exact
            />
            <Route
              path="/calendar/:year"
              element={<WorkingCalendar user={user} />}
              exact
            />
            <Route path="/users/:role" element={<Users user={user} />} exact />
            <Route
              path="/add_user/:role"
              element={<AddUser user={user} />}
              exact
            />
          </Route>
          <Route element={<LoggedInRoutes />}>
            <Route path="/" element={<Home />} exact />
            <Route
              path="/lesson/:lesson_id"
              element={<EditLesson mode="teacher" user={user} />}
              exact
            />
            <Route
              path="/attendance"
              element={<Attendance user={user} />}
              exact
            />
          </Route>
          <Route element={<NotLoggedInRoutes />}>
            <Route path="/login" element={<Login />} exact />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    );
  }
}

// function App() {
//   const { user } = useSelector((state) => ({ ...state }));

// }

export default App;
