import * as React from "react";
const Trash2 = (props) => (
  <svg
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508.4 508.4"
    {...props}
  >
    <path
      d="M108.8 453.2c2 25.6 24.8 47.2 49.6 47.2h204c24.8 0 47.6-21.6 49.2-47.2l16.8-336.8H80.8l28 336.8z"
      fill="#aeb1b4"
    />
    <path
      d="M447.2 112.4v-26c0-15.2-12.4-26-29.2-26H90c-15.6 0-31.2 10-31.2 26v26h388.4z"
      fill="#e36d60"
    />
    <path d="M54.4 114.4v2h2c-1.2 0-2-.8-2-2z" fill="#ccc" />
    <path
      d="M175.2 42.8l-3.6 15.6h165.2l-3.6-15.6c-4.8-20-25.6-36.4-46.8-36.4h-64c-21.6 0-42.4 16.4-47.2 36.4z"
      fill="#e36d60"
    />
    <path fill="#ccc" d="M340.8 56.4H175.2l166 .8z" />
    <path d="M362.4 508.4h-204c-28 0-53.2-24.4-55.6-53.6L75.2 119.2c0-2.4 1.6-4 3.6-4.4 2.4 0 4 1.6 4.4 3.6L110.8 454c2 25.2 23.6 46 47.6 46h204c24 0 45.6-21.2 47.2-46l16.8-337.2c0-2.4 2-4 4-3.6 2.4 0 4 2 3.6 4l-16.8 337.6c-1.6 29.2-26.8 53.6-54.8 53.6z" />
    <path d="M450 120H58c-2.4 0-4-1.6-4-4V86c0-19.6 20-30 39.2-30h328c20.4 0 33.2 11.6 33.2 30v30c-.4 2-2 4-4.4 4zm-388-8h384V86c0-14-9.2-22-25.2-22h-328c-15.2 0-31.2 7.6-31.2 22v26h.4z" />
    <path d="M338.8 60.8c-2 0-3.6-1.2-4-3.2l-4-16c-4.4-19.2-24-34-45.2-34h-64c-20.8 0-40.8 14.8-45.2 34l-4 15.2c-.4 2-2.8 3.6-4.8 2.8-2-.4-3.6-2.8-2.8-4.8l4-14.8c5.2-22.4 28.4-40 52.8-40h64c24.4 0 47.6 17.6 52.8 40l4 16c.4 2-.8 4.4-2.8 4.8h-.8zM250.8 472c-2.4 0-4-1.6-4-4V232c0-2.4 1.6-4 4-4s4 1.6 4 4v236c0 2-1.6 4-4 4zM250.8 208c-2.4 0-4-1.6-4-4v-56c0-2.4 1.6-4 4-4s4 1.6 4 4v56c0 2-1.6 4-4 4zM177.6 472c-2 0-4-1.6-4-4l-12-320c0-2.4 1.6-4 4-4s4 1.6 4 4l12 320c.4 2-1.6 4-4 4 .4 0 .4 0 0 0zM329.6 472c-.4 0-.4 0 0 0-2.4 0-4-2-4-4l8-212c0-2.4 2-4 4-4 2.4 0 4 2 4 4l-8 212c-.4 2.4-2 4-4 4zM338.8 232.4c-2.4 0-4-2-4-4l3.2-80.8c0-2.4 2-4 4-4 2.4 0 4 2 4 4l-3.2 80.8c0 2.4-2 4-4 4z" />
  </svg>
);
export default Trash2;
