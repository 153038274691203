import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Login from "../pages/login";

export default function AdminRoutes() {
  const user = useSelector((state) => state.user);
  if (!user) {
    return <Login />;
  }
  if (user.role && user.role === "admin") {
    return <Outlet />;
  }
  return <Navigate to="/" />;
}
