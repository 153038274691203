import { Home } from "../../svg";
import "./style.css";

export default function NotFound() {
  return (
    <div className="not_found">
      <div className="nf_logo">
        <img
          src="/images/kidum_owl.png"
          width={320}
          height={320}
          alt="kidum owl logo"
        />
      </div>
      <h2>העמוד לא נמצא</h2>
      <div className="nf_home">
        <a href="/">
          <Home width="50" height="50" color="#C42A60" />
          <h3>לחץ כאן כדי לחזור לדף הבית</h3>
        </a>
      </div>
    </div>
  );
}
