import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function NotLoggedInRoutes() {
  const user = useSelector((state) => state.user);
  if (user) {
    if (user.role === "admin") {
      return <Navigate to="/admin" />;
    }
    return <Navigate to="/" />;
  }
  return <Outlet />;
}
