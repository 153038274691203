import "./style.css";
import { useState } from "react";
import Trash2 from "../../svg/Trash2";
import Checkbox from "../inputs/checkbox";

export default function Presence({
  deletePresence,
  handlePresenceChanged,
  presenceRecord,
  mode = "admin",
}) {
  const student_id = presenceRecord.student._id;
  const first_name = presenceRecord.student.first_name;
  const last_name = presenceRecord.student.last_name;
  const [attended, setAttended] = useState(presenceRecord.attended);
  const [missing, setMissing] = useState(presenceRecord.present === "no");
  const [late, setLate] = useState(presenceRecord.was_late);
  const [early, setEarly] = useState(presenceRecord.left_early);

  const handleChange = (event) => {
    switch (event.target.id) {
      case "attended":
        if (attended) {
          // if switching to not attended, turn off the other two
          setLate(false);
          setEarly(false);
          handlePresenceChanged(student_id, false, false, false);
        } else {
          handlePresenceChanged(student_id, true, late, early);
        }
        setAttended(!attended);
        setMissing(attended);
        break;
      case "missing":
        if (!missing) {
          // if switching to missing, turn off the other two (and the attended)
          setLate(false);
          setEarly(false);
          handlePresenceChanged(student_id, false, false, false);
        } else {
          handlePresenceChanged(student_id, true, late, early);
        }
        setAttended(missing);
        setMissing(!missing);
        break;
      case "late":
        handlePresenceChanged(student_id, attended, !late, early);
        setLate(!late);
        break;
      case "early":
        handlePresenceChanged(student_id, attended, late, !early);
        setEarly(!early);
        break;
      default:
        console.log("NOT KNOWN");
        break;
    }
    // if (value !== event.target.value) {
    //   handlePresenceChanged(student_id, event.target.value);
    // }
    // setValue(event.target.value);
  };

  return (
    <div dir="rtl" className="presence_entry">
      <div key="name">
        {first_name}&nbsp;{last_name}
      </div>
      {mode === "teacher" && (
        <div key="selector" className="presence_select">
          <Checkbox
            id="missing"
            label="חסר"
            isSelected={missing}
            onCheckboxChange={handleChange}
          />
          <Checkbox
            id="attended"
            label="נוכח"
            isSelected={attended}
            onCheckboxChange={handleChange}
          />
          {attended && (
            <Checkbox
              id="late"
              label="איחור"
              isSelected={late}
              disabled={!attended}
              onCheckboxChange={handleChange}
            />
          )}
          {attended && (
            <Checkbox
              id="early"
              label="יצא"
              isSelected={early}
              disabled={!attended}
              onCheckboxChange={handleChange}
            />
          )}
          {/* <select value={value} onChange={handleChange}>
            <option key="no" value="no">
              לא נוכח
            </option>
            <option key="yes" value="yes">
              נוכח
            </option>
            <option key="partial" value="partial">
              חלקי
            </option>
          </select> */}
        </div>
      )}
      {mode === "admin" && (
        <div
          key="trasher"
          className="presence_trash"
          onClick={() => {
            deletePresence(student_id, `${first_name} ${last_name}`);
          }}
        >
          <Trash2 width="40" height="40" />
        </div>
      )}
    </div>
  );
}
