import * as React from "react";
const Mask = (props) => (
  <svg
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M37.728 49.312v186.48C37.728 413.104 256 512 256 512s218.272-98.896 218.272-276.208V49.312S396.128 0 256 0 37.728 49.312 37.728 49.312z"
      fill="#666"
    />
    <path
      d="M256 0C115.872 0 37.728 49.312 37.728 49.312v186.48C37.728 413.104 256 512 256 512V0z"
      fill="#f91e1e"
    />
    <g fill="#fff">
      <path d="M364.6 286.992s-29.104 74.104-108.6 74.104-108.6-74.104-108.6-74.104 108.6 74.104 217.2 0zM224.8 153.424c-16.072-37.664-59.632-55.168-97.296-39.096a74.155 74.155 0 00-39.096 39.096H224.8zM423.592 153.424c-16.072-37.664-59.632-55.168-97.296-39.096a74.155 74.155 0 00-39.096 39.096h136.392z" />
    </g>
  </svg>
);
export default Mask;
