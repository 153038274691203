import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import "./users.css";
import Trash2 from "../../svg/Trash2";
import Mask from "../../svg/Mask";
import Popup from "reactjs-popup";
import UserEdit from "../edit_user/edit_user";
import GridLoader from "react-spinners/GridLoader";
import {
  deleteUser,
  getUsersWithRole,
  updateUser,
} from "../../data_access/user";

export default function Users() {
  const user = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState({});
  const { role } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const closeModal = useCallback(() => setOpen(false), []);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const role_names = {
    student: "תלמיד",
    teacher: "מורה",
    admin: "אדמין",
    parent: "הורה",
  };

  const add_user = () => {
    navigate(`/add_user/${role}`);
  };

  const saveUserFunction = async (formdata) => {
    console.log("save user: ", formdata);
    closeModal();
    setLoading(true);
    await updateUser(user.token, formdata);
    await getUsers();
    setError("");
    setLoading(false);
  };

  const edit_user = (userRecord, index) => {
    console.log(userRecord);
    setUserData(userRecord);
    setOpen(true);
  };

  const delete_user = async (del_user_id, full_name) => {
    if (del_user_id === user.id) {
      alert(`Error: Can't delete your own user!`);
      return;
    }
    if (window.confirm(`אתה בטוח שברצונך למחוק את המשתמש ${full_name}`)) {
      await deleteUser(user.token, del_user_id);
      await getUsers();
    }
    // navigate(`/admin`);
  };

  const getUsers = useCallback(async () => {
    try {
      const data = await getUsersWithRole(user.token, role);
      setUsers(data);
    } catch (error) {
      console.log(error);
      setUsers([]);
    }
  }, [role, user.token]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div className="users">
      <Header page="users" />
      <SubHeader />
      <div className="users_middle" dir="rtl">
        <div className="add_user" dir="rtl">
          <button
            className="main_btn"
            onClick={() => {
              add_user();
            }}
          >
            הוספת &nbsp;
            {role_names[role]}
          </button>
        </div>
        <GridLoader
          color="#F7931E"
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <div className="users_list">
          {users.length === 0 ? (
            <span>No Users Yet</span>
          ) : (
            users.map((user, index) => (
              <div
                key={user._id}
                className="user_entry"
                onClick={() => {
                  edit_user(user, index);
                }}
              >
                <div className="user_name">
                  {user.first_name}
                  &nbsp;
                  {user.last_name}
                </div>
                <div className="operations">
                  {user.role &&
                    (user.role === "student" || user.role === "parent") && (
                      <div
                        className="impersonate_user"
                        onClick={() => {
                          navigate(`/as/${user._id}`);
                        }}
                      >
                        <Mask width="30" height="30" />
                      </div>
                    )}
                  <div
                    className="delete_user"
                    onClick={() => {
                      delete_user(
                        user._id,
                        user.first_name + " " + user.last_name
                      );
                    }}
                  >
                    <Trash2 width="30" height="30" />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
          <UserEdit
            userID={userData._id}
            saveUserFunction={saveUserFunction}
            closePopupFunction={closeModal}
          />
        </Popup>
        <div className="add_user" dir="rtl">
          <button
            className="main_btn"
            onClick={() => {
              add_user();
            }}
          >
            הוספת &nbsp;
            {role_names[role]}
          </button>
          {error && <div className="error_text">{error}</div>}
        </div>
      </div>
    </div>
  );
}
