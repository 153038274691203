function Logout({ color = "#000000", width = 32, height = 32 }) {
  return (
    <svg
      id="Logout"
      viewBox="0 0 122.97 109.97"
      width={width}
      height={height}
      stroke={color}
      strokeWidth="4px"
      fill="none"
    >
      <path d="m110.8,33.97v-12.86c0-6.6-5.4-12-12-12H26.8c-6.6,0-12,5.4-12,12v72c0,6.6,5.4,12,12,12h72c6.6,0,12-5.4,12-12v-14.14" />
      <line x1="62.47" y1="56.97" x2="119.47" y2="56.97" />
      <polyline points="100.47 42.97 119.47 56.97 101.47 68.97" />
    </svg>
  );
}

export default Logout;
