import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import moment from "moment";
import "moment/locale/he";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { getTeachers } from "../../data_access/user";
import { dateRangeFromCalendar } from "../../functions/date";
import GridLoader from "react-spinners/GridLoader";
import Event from "../../components/Event/event";
import {
  copyLastWeekLessons,
  createLesson,
  getLessonsForTeacherInDates,
  updateLesson,
} from "../../data_access/lesson";

const DragAndDropCalendar = withDragAndDrop(Calendar);

export default function Schedule() {
  const user = useSelector((state) => state.user);
  const middle = useRef(null);
  const [height, setHeight] = useState();
  useEffect(() => {
    setHeight(middle.current.clientHeight);
  }, []);
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const navigate = useNavigate();
  const [curTeacher, setCurTeacher] = useState(
    sessionStorage.getItem("sch_cur_teacher")
      ? sessionStorage.getItem("sch_cur_teacher")
      : 0
  );
  const [curDateRangeStart, setCurDateRangeStart] = useState(
    sessionStorage.getItem("sch_cur_range_start")
      ? new Date(sessionStorage.getItem("sch_cur_range_start"))
      : new Date()
  );
  const [curDateRangeEnd, setCurDateRangeEnd] = useState(
    sessionStorage.getItem("sch_cur_range_end")
      ? new Date(sessionStorage.getItem("sch_cur_range_end"))
      : new Date()
  );
  const [view, setView] = useState(
    sessionStorage.getItem("sch_cur_view")
      ? sessionStorage.getItem("sch_cur_view")
      : Views.DAY
  );

  const [date, setDate] = useState(
    sessionStorage.getItem("sch_cur_date")
      ? new Date(sessionStorage.getItem("sch_cur_date"))
      : new Date()
  );

  const getTeachersWrapped = useCallback(async () => {
    try {
      const data = await getTeachers(user.token);
      setTeachers(data);
      if (curTeacher === 0) {
        setCurTeacher(data[0]._id);
      }
    } catch (error) {
      console.log(error);
      setTeachers([]);
    }
  }, [curTeacher, user.token]);

  const getAllLessons = useCallback(async () => {
    try {
      if (curTeacher === 0) return;
      setLoading(true);
      const start_date = curDateRangeStart.toISOString();
      const end_date = curDateRangeEnd.toISOString();
      const data = await getLessonsForTeacherInDates(
        user.token,
        curTeacher,
        start_date,
        end_date
      );
      const mapped = data.map((lesson) => {
        return {
          start: new Date(lesson.start_time),
          end: new Date(lesson.end_time),
          title: lesson.title,
          students: lesson.students,
          id: lesson._id._id,
          teacher_id: lesson.teacher_id,
        };
      });
      setLessons(mapped);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLessons([]);
      setLoading(false);
    }
  }, [user.token, curTeacher, curDateRangeStart, curDateRangeEnd]);

  useEffect(() => {
    getTeachersWrapped();
  }, [getTeachersWrapped]);

  useEffect(() => {
    getAllLessons();
  }, [curTeacher, getAllLessons]);

  const copy_lessons = useCallback(async () => {
    try {
      setLoading(true);
      const start_date = curDateRangeStart.toISOString();
      await copyLastWeekLessons(user.token, start_date);
      setLoading(false);
      getAllLessons();
    } catch (error) {
      setLoading(false);
      window.alert(error.response.data.message);
    }
  }, [curDateRangeStart, getAllLessons, user.token]);

  const createLessonWrapped = useCallback(
    async (start, end, title) => {
      try {
        setLoading(true);
        const start_date = start.toISOString();
        const end_date = end.toISOString();
        const data = await createLesson(
          user.token,
          curTeacher,
          start_date,
          end_date,
          title
        );
        setLoading(false);
        navigate(`/admin_lesson/${data._id}`);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setLessons([]);
      }
    },
    [curTeacher, navigate, user.token]
  );

  const updateLessonWrapped = useCallback(
    async (id, start, end, title) => {
      try {
        setLoading(true);
        const start_date = start.toISOString();
        const end_date = end.toISOString();
        await updateLesson(user.token, id, title, start_date, end_date);
        setLoading(false);
        getAllLessons();
      } catch (error) {
        setLoading(false);
        window.alert(error.response.data.message);
      }
    },
    [getAllLessons, user.token]
  );

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      if (curTeacher === "ALL") {
        window.alert("לא ניתן ליצור שיעור, יש לבחור מורה");
        return;
      }
      const title = window.prompt("שם השיעור");
      if (title) {
        createLessonWrapped(start, end, title);
        // setEvents((prev) => [...prev, { start, end, title }]);
      }
    },
    [curTeacher, createLessonWrapped]
  );

  const handleSelectEvent = useCallback(
    (event) => navigate(`/admin_lesson/${event.id}`),
    [navigate]
  );

  const handleRangeChanget = useCallback((range) => {
    const newRange = dateRangeFromCalendar(range);
    setCurDateRangeStart(newRange.start);
    setCurDateRangeEnd(newRange.end);
    sessionStorage.setItem("sch_cur_range_start", newRange.start.toISOString());
    sessionStorage.setItem("sch_cur_range_end", newRange.end.toISOString());
  }, []);

  const changeTeacher = (event) => {
    sessionStorage.setItem("sch_cur_teacher", event.target.value);
    setCurTeacher(event.target.value);
  };

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      try {
        updateLessonWrapped(event.id, start, end, event.title);
      } catch (error) {
        console.log(error);
      }
    },
    [updateLessonWrapped]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      try {
        updateLessonWrapped(event.id, start, end, event.title);
      } catch (error) {
        console.log(error);
      }
    },
    [updateLessonWrapped]
  );

  const onNavigate = useCallback(
    (newDate) => {
      sessionStorage.setItem("sch_cur_date", newDate.toISOString());
      setDate(newDate);
    },
    [setDate]
  );

  const onView = useCallback(
    (newView) => {
      sessionStorage.setItem("sch_cur_view", newView);
      setView(newView);
    },
    [setView]
  );

  const rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#3174ad";
    const ind = teachers.findIndex((t) => {
      return t._id === event.teacher_id;
    });
    if (ind > -1) {
      const palette = [
        [244, 67, 54],
        [61, 133, 198],
        [143, 206, 0],
        [180, 95, 6],
        [129, 76, 158],
        [49, 57, 140],
        [191, 144, 0],
        [155, 73, 180],
        [58, 123, 154],
        [212, 167, 58],
        [218, 94, 130],
      ];
      backgroundColor = rgbToHex(
        palette[ind % 10][0],
        palette[ind % 10][1],
        palette[ind % 10][2]
      );
    }
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "7px",
      color: "white",
      fontSize: "24px",
    };
    return {
      style: style,
    };
  };

  moment.locale("he");

  const localizer = momentLocalizer(moment);
  const culture = "he";
  const messages = {
    week: "שבוע",
    work_week: "שבוע עבודה",
    day: "יום",
    month: "חודש",
    previous: "<<",
    next: ">>",
    today: "היום",
    agenda: "יומן",

    showMore: (total) => `עוד+${total}`,
  };

  const { components } = useMemo(
    () => ({
      components: {
        event: Event,
      },
    }),
    []
  );

  return (
    <div className="schedule" style={{ height: `${height + 150}px` }}>
      <Header page="schedule" />
      <SubHeader />
      <div className="schedule_middle" ref={middle}>
        <div className="teacher_select" dir="rtl">
          <label>מורה: </label>
          <select value={curTeacher} onChange={changeTeacher}>
            {teachers.map((entry) => (
              <option key={entry._id} value={entry._id}>
                {entry.first_name}&nbsp;{entry.last_name}
              </option>
            ))}
            <option key="ALL" value="ALL">
              כל המורים
            </option>
          </select>
          {view === "week" && curTeacher === "ALL" && (
            <div className="copy_lessons">
              <button
                className="main_btn"
                onClick={() => {
                  copy_lessons();
                }}
              >
                העתק את המערכת משבוע שעבר
              </button>
            </div>
          )}
        </div>
        <DragAndDropCalendar
          localizer={localizer}
          culture={culture}
          components={components}
          rtl={true}
          min={new Date(0, 0, 0, 9, 0, 0)}
          max={new Date(0, 0, 0, 19, 0, 0)}
          events={lessons}
          date={date}
          view={view}
          onView={onView}
          onNavigate={onNavigate}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          selectable
          resizable
          messages={messages}
          onRangeChange={handleRangeChanget}
          eventPropGetter={eventStyleGetter}
          style={{ height: 700 }}
          views={{
            day: true,
            week: true,
            month: true,
          }}
        />
        <div className="loading_spinner">
          <GridLoader
            color="#3080c0"
            loading={loading}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    </div>
  );
}
