import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import "./style.css";
import apiWrapper from "../../helpers/api_helper";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import he from "date-fns/locale/he";
import GridLoader from "react-spinners/GridLoader";

export default function PerSubjectReport() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [curSubject, setCurSubject] = useState("");
  const initalAttendanceTable = useMemo(() => ({}), []);

  let start_date = new Date();
  start_date.setDate(start_date.getDate() - start_date.getDay());
  let end_date = new Date(start_date);
  end_date.setDate(end_date.getDate() + 7);

  const [startDate, setStartDate] = useState(start_date);
  const [endDate, setEndDate] = useState(end_date);
  registerLocale("he", he); // register it with the name you want
  setDefaultLocale("he");

  const [attendanceTable, setAttendanceTable] = useState(initalAttendanceTable);

  const getSubjects = useCallback(async () => {
    try {
      var url = `${process.env.REACT_APP_BACKEND_URL}/api/lesson_subjects`;
      const data = await apiWrapper.get(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setSubjects(data);
      setCurSubject(data[0]);
    } catch (error) {
      setSubjects([]);
    }
  }, [user.token]);

  const didAttend = (attendance) => {
    return (
      attendance.attended ||
      attendance.present === "yes" ||
      attendance.present === "partial"
    );
  };

  const setAttendanceSummaryFromAPI = useCallback((data) => {
    let attendanceTable = {};
    console.log(data);
    for (const l of data) {
      if (!Object.keys(attendanceTable).includes(l.title)) {
        let subjAttDict = {}; // this will keep presences of students for cur subject
        for (const p of l.presences) {
          const fullName = `${p.user.first_name} ${p.user.last_name}`;
          if (Object.keys(subjAttDict).includes(fullName)) {
            subjAttDict[fullName]["numLessons"] += 1;
            if (didAttend(p)) subjAttDict[fullName]["attended"] += 1;
            if (p.present === "no") subjAttDict[fullName]["notAttended"] += 1;
            if (p.was_late) subjAttDict[fullName]["wasLate"] += 1;
            if (p.left_early) subjAttDict[fullName]["leftEarly"] += 1;
            if (p.lesson_rec.canceled) {
              subjAttDict[fullName]["canceled"] += 1;
            }
          } else {
            subjAttDict[fullName] = {
              numLessons: 1,
              canceled: p.lesson_rec.canceled ? 1 : 0,
              attended: didAttend(p) ? 1 : 0,
              notAttended: p.present === "no" ? 1 : 0,
              wasLate: p.was_late ? 1 : 0,
              leftEarly: p.left_early ? 1 : 0,
            };
          }
        }
        attendanceTable[l.title] = subjAttDict;
      }
    }
    setAttendanceTable(attendanceTable);
  }, []);

  const getAttendanceForDates = useCallback(async () => {
    if (!curSubject || curSubject.length === 0) return;
    try {
      console.log("getAttendanceForDates: ", curSubject);
      setLoading(true);
      const start_date = startDate.toISOString();
      const end_date = endDate.toISOString();
      var url = `${process.env.REACT_APP_BACKEND_URL}/api/attendance_for_subject?start_date=${start_date}&end_date=${end_date}&subject=${curSubject}`;
      const data = await apiWrapper.get(url, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setAttendanceSummaryFromAPI(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setAttendanceTable(initalAttendanceTable);
      setLoading(false);
    }
  }, [
    user,
    startDate,
    endDate,
    curSubject,
    initalAttendanceTable,
    setAttendanceSummaryFromAPI,
  ]);

  useEffect(() => {
    getSubjects();
  }, [getSubjects]);

  useEffect(() => {
    getAttendanceForDates();
  }, [getAttendanceForDates]);

  const changeSubject = (event) => {
    setCurSubject(event.target.value);
  };

  return (
    <div className="reports">
      <Header page="reports" />
      <SubHeader />
      <div className="reports_middle">
        <div className="reports_header">דו"ח נוכחות למקצוע</div>
        <div className="reports_student_select" dir="rtl">
          <label>מקצוע: </label>
          <select value={curSubject} onChange={changeSubject}>
            {subjects.map((entry) => (
              <option key={entry} value={entry}>
                {entry}
              </option>
            ))}
          </select>
        </div>
        <div className="reports_date_range" dir="rtl">
          <div>
            <label>מתאריך: </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              popperPlacement="right"
              locale="he"
            />
          </div>
          <div>
            <label>עד תאריך: </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              popperPlacement="left"
              locale="he"
            />
          </div>
          <button
            className="reports_buttons_button"
            onClick={() => {
              const newURL = `/per_subject_pdf/${curSubject}/${startDate.toISOString()}/${endDate.toISOString()}`;
              window.open(newURL, "_blank");
            }}
          >
            דו"ח להדפסה
          </button>
        </div>
        <GridLoader
          color="#F7931E"
          loading={loading}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        {!loading && (
          <div className="reports_summary_table reports_summary_table_header">
            <div className="rst_label">תלמיד</div>
            <div className="rst_label">סה"כ בתקופה</div>
            <div className="rst_label">מתוכם נכח</div>
            <div className="rst_label">החסיר</div>
            <div className="rst_label">ללא דיווח</div>
            <div className="rst_label">בוטלו</div>
            <div className="rst_label">איחורים</div>
            <div className="rst_label">יציאה מוקדמת</div>
          </div>
        )}
        {!loading &&
          curSubject &&
          curSubject.length > 0 &&
          attendanceTable[curSubject] &&
          Object.keys(attendanceTable[curSubject]).map((k) => {
            return (
              <div className="reports_summary_table" key={k}>
                <div className="rst_label">{k}</div>
                <div className="rst_label">
                  {attendanceTable[curSubject][k].numLessons}
                </div>
                <div className="rst_label">
                  {attendanceTable[curSubject][k].attended}
                </div>
                <div className="rst_label">
                  {attendanceTable[curSubject][k].notAttended}
                </div>
                <div className="rst_label">
                  {attendanceTable[curSubject][k].numLessons -
                    attendanceTable[curSubject][k].attended -
                    attendanceTable[curSubject][k].notAttended}
                </div>
                <div className="rst_label">
                  {attendanceTable[curSubject][k].canceled}
                </div>
                <div className="rst_label">
                  {attendanceTable[curSubject][k].wasLate}
                </div>
                <div className="rst_label">
                  {attendanceTable[curSubject][k].leftEarly}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
