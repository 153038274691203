import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import SubHeader from "../../components/header/sub";
import moment from "moment";
import "moment/locale/he";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { getTeachers } from "../../data_access/user";
import { dateRangeFromCalendar } from "../../functions/date";
import apiWrapper from "../../helpers/api_helper";
import GridLoader from "react-spinners/GridLoader";
import Event from "../../components/Event/event";

export default function ResSchedule() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [resources, setResources] = useState([]);
  const [curDateRangeStart, setCurDateRangeStart] = useState(
    sessionStorage.getItem("sch_cur_range_start")
      ? new Date(sessionStorage.getItem("sch_cur_range_start"))
      : new Date()
  );
  const [curDateRangeEnd, setCurDateRangeEnd] = useState(
    sessionStorage.getItem("sch_cur_range_end")
      ? new Date(sessionStorage.getItem("sch_cur_range_end"))
      : new Date()
  );
  const [date, setDate] = useState(
    sessionStorage.getItem("sch_cur_date")
      ? new Date(sessionStorage.getItem("sch_cur_date"))
      : new Date()
  );

  const getTeachersWrapped = useCallback(async () => {
    try {
      const data = await getTeachers(user.token);
      setTeachers(data);
    } catch (error) {
      console.log(error);
      setTeachers([]);
    }
  }, [user.token]);

  const getAllLessons = useCallback(async () => {
    try {
      setLoading(true);
      const start_date = curDateRangeStart.toISOString();
      const end_date = curDateRangeEnd.toISOString();
      const data = await apiWrapper.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/get_lessons_with_students?start_date=${start_date}&end_date=${end_date}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const mapped = data.map((lesson) => {
        return {
          start: new Date(lesson.start_time),
          end: new Date(lesson.end_time),
          title: lesson.title,
          canceled: lesson.canceled,
          canceled_reason: lesson.canceled_reason,
          id: lesson._id._id,
          students: lesson.students,
          teacher_id: lesson.teacher_id,
          resourceId: lesson.teacher_id,
        };
      });

      let res = [];
      let tlist = [];
      for (const l of mapped) {
        if (!tlist.includes(l.teacher_id)) {
          tlist.push(l.teacher_id);
          const teacher = teachers.find((t) => {
            return t._id === l.teacher_id;
          });
          res.push({
            resourceId: teacher._id,
            resourceTitle: `${teacher.first_name} ${teacher.last_name}`,
            therapist: teacher.therapist,
          });
        }
      }
      res = res.sort((a, b) => a.therapist - b.therapist);
      setResources(res);

      setLessons(mapped);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLessons([]);
      setLoading(false);
    }
  }, [user.token, teachers, curDateRangeStart, curDateRangeEnd]);

  useEffect(() => {
    getTeachersWrapped();
  }, [getTeachersWrapped]);

  useEffect(() => {
    getAllLessons();
  }, [getAllLessons]);

  const handleRangeChanget = useCallback((range) => {
    const newRange = dateRangeFromCalendar(range);
    setCurDateRangeStart(newRange.start);
    setCurDateRangeEnd(newRange.end);
    sessionStorage.setItem("sch_cur_range_start", newRange.start.toISOString());
    sessionStorage.setItem("sch_cur_range_end", newRange.end.toISOString());
  }, []);

  const onNavigate = useCallback(
    (newDate) => {
      sessionStorage.setItem("sch_cur_date", newDate.toISOString());
      setDate(newDate);
    },
    [setDate]
  );

  const rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#3174ad";
    const ind = teachers.findIndex((t) => {
      return t._id === event.teacher_id;
    });
    if (ind > -1) {
      const palette = [
        [244, 67, 54],
        [61, 133, 198],
        [143, 206, 0],
        [180, 95, 6],
        [129, 76, 158],
        [49, 57, 140],
        [191, 144, 0],
        [155, 73, 180],
        [58, 123, 154],
        [212, 167, 58],
        [218, 94, 130],
      ];
      backgroundColor = rgbToHex(
        palette[ind % 10][0],
        palette[ind % 10][1],
        palette[ind % 10][2]
      );
    }
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "7px",
      color: "white",
      fontSize: "24px",
    };
    return {
      style: style,
    };
  };

  moment.locale("he");

  const localizer = momentLocalizer(moment);
  const culture = "he";
  const messages = {
    week: "שבוע",
    work_week: "שבוע עבודה",
    day: "יום",
    month: "חודש",
    previous: "<<",
    next: ">>",
    today: "היום",
    agenda: "יומן",

    showMore: (total) => `עוד+${total}`,
  };

  const { components } = useMemo(
    () => ({
      components: {
        event: Event,
      },
    }),
    []
  );

  return (
    <div className="schedule">
      <Header page="schedule" />
      <SubHeader />
      <div className="schedule_middle">
        <Calendar
          localizer={localizer}
          components={components}
          resources={resources}
          culture={culture}
          rtl={true}
          min={new Date(0, 0, 0, 9, 0, 0)}
          max={new Date(0, 0, 0, 19, 0, 0)}
          events={lessons}
          date={date}
          defaultView={Views.DAY}
          onNavigate={onNavigate}
          messages={messages}
          onRangeChange={handleRangeChanget}
          eventPropGetter={eventStyleGetter}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          style={{ height: 800 }}
          views={{
            day: true,
          }}
        />
        <div className="loading_spinner">
          <GridLoader
            color="#3080c0"
            loading={loading}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    </div>
  );
}
