import apiWrapper from "../helpers/api_helper";
export const updateprofilePicture = async (url, token) => {
  try {
    await apiWrapper.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/updateProfilePicture`,
      {
        url,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return "ok";
  } catch (error) {
    return error.response.data.message;
  }
};

export const getUserById = async (token, userID) => {
  try {
    const cacheKey = `user:${userID}`;
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return JSON.parse(cached);
    }
    const data = await apiWrapper.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/user?id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem(cacheKey, JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const clearCacheForAllUsersList = () => {
  sessionStorage.removeItem("usersbyRole:admin");
  sessionStorage.removeItem("usersbyRole:teacher");
  sessionStorage.removeItem("usersbyRole:student");
  sessionStorage.removeItem("usersbyRole:parent");
};

export const createUser = async (
  token,
  mobile,
  first_name,
  last_name,
  role
) => {
  try {
    await apiWrapper.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/register`,
      {
        mobile,
        first_name,
        last_name,
        role,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.removeItem(`usersbyRole:${role}`);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateUser = async (token, formdata) => {
  try {
    const cacheKey = `user:${formdata._id}`;
    sessionStorage.removeItem(cacheKey);
    await apiWrapper.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/user`,
      {
        id: formdata._id,
        first_name: formdata.first_name,
        last_name: formdata.last_name,
        mobile: formdata.mobile,
        sms_allowed: formdata.sms_allowed,
        therapist: formdata.therapist,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    clearCacheForAllUsersList();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteUser = async (token, del_user_id) => {
  try {
    const cacheKey = `user:${del_user_id}`;
    sessionStorage.removeItem(cacheKey);
    await apiWrapper.delete(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
      data: {
        id: del_user_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    clearCacheForAllUsersList();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addChildToParent = async (token, parentId, childId) => {
  try {
    sessionStorage.removeItem(`user:${parentId}`);
    sessionStorage.removeItem(`user:${childId}`);
    await apiWrapper.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/child`,
      { id: parentId, child_id: childId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const removeChildFromParent = async (token, parentId, childId) => {
  try {
    sessionStorage.removeItem(`user:${parentId}`);
    sessionStorage.removeItem(`user:${childId}`);
    await apiWrapper.delete(`${process.env.REACT_APP_BACKEND_URL}/api/child`, {
      data: {
        id: parentId,
        child_id: childId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUsersWithRole = async (token, role) => {
  try {
    const cacheKey = `usersbyRole:${role}`;
    const cached = sessionStorage.getItem(cacheKey);
    if (cached) {
      return JSON.parse(cached);
    }
    const data = await apiWrapper.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/users?role=${role}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem(cacheKey, JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStudents = async (token) => {
  try {
    const data = await getUsersWithRole(token, "student");
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getTeachers = async (token) => {
  try {
    const teachers = await getUsersWithRole(token, "teacher");
    const admins = await getUsersWithRole(token, "admin");
    return [...teachers, ...admins];
  } catch (error) {
    console.log(error);
    throw error;
  }
};
