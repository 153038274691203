import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { Kidum } from "../../svg";
import { useSelector } from "react-redux";
import AttendanceEdit from "../../svg/attendance_edit";
import Attendance from "../../svg/attendance";
import CogWheel from "../../svg/cogwheel";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Logout from "../../svg/logout";

export default function Header({ page, setVisible }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    Cookies.set("user", "", { expires: 90 });
    sessionStorage.clear();
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  return (
    <header>
      <div className="header_left">
        <Link to="/" className="header_logo">
          <div className="circle">
            <Kidum />
          </div>
        </Link>
      </div>
      <div className="header_middle">
        <div>
          <span>{user.first_name}</span>
        </div>
      </div>
      <div className="header_right">
        {user.role && (user.role === "teacher" || user.role === "admin") && (
          <Link to="/">
            <AttendanceEdit width="24" height="24" />
          </Link>
        )}
        {user.role && (user.role === "teacher" || user.role === "admin") && (
          <Link to="/attendance">
            <Attendance width="24" height="24" />
          </Link>
        )}
        {user.role && user.role === "admin" && (
          <Link to="/admin">
            <CogWheel width="24" height="24" />
          </Link>
        )}
        <div
          className="hover3"
          onClick={() => {
            logout();
          }}
        >
          <Logout width="24" height="24" />
        </div>
      </div>
    </header>
  );
}
