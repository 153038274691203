import * as React from "react";
const Student = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 29.095 29.095"
    {...props}
  >
    <path d="M10.594 4.476c-.05.166-.103.332-.13.506a4.047 4.047 0 108.038.666c0-.447-.091-.869-.224-1.271l.822-1.134-1.473-.519.289-1.504-1.801.514L15.292 0l-1.537 1.602L12.566.221l-.561 1.822-1.91-.625.722 1.608-1.392.39 1.169 1.06z" />
    <path d="M18.149 16.184l.012-2.624 5.73-6.92-1.742-1.441-4.42 5.336-1.27-.23s-.555 1.701-2.078 1.791c-1.524.088-2.101-1.791-2.101-1.791l-2.445.575-1.961 5.304H1.507v1.928h1.159v10.983h.942V18.111h7.232l.014 1.361H9.325v.661h1.312v8.457h2.897v-8.457h1.917v8.457h2.898v-8.457h1.141v-.661h-1.342l.002-1.361h7.05v10.983h.94V18.111h1.447v-1.928h-9.438zm-8.239 0l.882-2.455.027 2.455H9.91z" />
  </svg>
);
export default Student;

