import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import "./edit_user.css";
import { Formik, Form } from "formik";
import LoginInput from "../../components/inputs/login";
import Checkbox from "../../components/inputs/checkbox";
import * as Yup from "yup";
import Trash2 from "../../svg/Trash2";
import GridLoader from "react-spinners/GridLoader";
import {
  addChildToParent,
  getStudents,
  getUserById,
  removeChildFromParent,
} from "../../data_access/user";

export default function UserEdit({
  userID,
  saveUserFunction,
  closePopupFunction,
}) {
  const emptyUserData = useMemo(
    () => ({
      first_name: "",
      last_name: "",
      mobile: "",
      children: [],
    }),
    []
  );
  const [userData, setUserData] = useState(emptyUserData);
  const [students, setStudents] = useState([]);
  const [curStudent, setCurStudent] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  const getUser = useCallback(async () => {
    try {
      const data = await getUserById(user.token, userID);
      setUserData(data);
    } catch (error) {
      console.log(error);
      setUserData(emptyUserData);
    }
  }, [emptyUserData, userID, user.token]);

  const getStudentsWrapped = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getStudents(user.token);
      setStudents(data);
      setCurStudent(data[0]._id);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setStudents([]);
      setLoading(false);
    }
  }, [user.token]);

  useEffect(() => {
    getStudentsWrapped();
    getUser();
  }, [getStudentsWrapped, getUser]);

  const add_child = async () => {
    await addChildToParent(user.token, userData._id, curStudent);
    getUser();
  };

  const delete_child = async (child_id, full_name) => {
    if (window.confirm(` בטוח שברצונך למחוק את הילד/ה ${full_name}?`)) {
      await removeChildFromParent(user.token, userData._id, child_id);
      getUser();
    }
  };

  const userEditValidation = Yup.object({
    mobile: Yup.string()
      .required("Mobile is required")
      .matches(/^05\d\d\d\d\d\d\d\d/, "please provide a valid phone number"),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
  });

  const smsRecieverRoles = ["admin", "teacher"];
  const therapistRoles = ["admin", "teacher"];

  return (
    <div className="user_edit">
      <Formik
        enableReinitialize
        validationSchema={userEditValidation}
        initialValues={userData}
        onSubmit={(values) => {
          saveUserFunction(values);
        }}
      >
        {(props) => {
          return (
            <Form>
              <GridLoader
                color="#F7931E"
                loading={loading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <LoginInput
                type="text"
                name="first_name"
                label="שם פרטי:"
                onChange={props.handleChange}
                value={props.values.first_name}
              />
              <LoginInput
                type="text"
                name="last_name"
                label="שם משפחה:"
                onChange={props.handleChange}
                value={props.values.last_name}
              />
              <LoginInput
                type="text"
                name="mobile"
                label="טלפון:"
                onChange={props.handleChange}
                value={props.values.mobile}
              />
              {userData && smsRecieverRoles.includes(userData.role) && (
                <Checkbox
                  id="sms_allowed"
                  name="sms_allowed"
                  label="לשלוח סמס"
                  isSelected={props.values.sms_allowed}
                  onCheckboxChange={props.handleChange}
                />
              )}
              {userData && therapistRoles.includes(userData.role) && (
                <Checkbox
                  id="therapist"
                  name="therapist"
                  label="מטפל/ת"
                  isSelected={props.values.therapist}
                  onCheckboxChange={props.handleChange}
                />
              )}
              {userData && userData.role === "parent" && (
                <div className="children_section">
                  {userData.children.length > 0 ? (
                    <div className="children_list">
                      {userData.children.map((c) => {
                        return (
                          <div className="child_entry" key={c._id}>
                            <div className="child_name">
                              {c.first_name} {c.last_name}
                            </div>
                            <div
                              className="child_ops"
                              data={c._id}
                              onClick={(e) =>
                                delete_child(
                                  c._id,
                                  `${c.first_name} ${c.last_name}`
                                )
                              }
                            >
                              <Trash2 />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="children_empty_list">לא הוגדרו ילדים</div>
                  )}
                  <div className="children_buttons_section">
                    <button
                      type="button"
                      className="main_btn"
                      onClick={add_child}
                    >
                      הוספת ילד/ה
                    </button>
                    <select
                      value={curStudent}
                      onChange={(e) => {
                        setCurStudent(e.target.value);
                      }}
                    >
                      {students.map((s) => {
                        return (
                          <option value={s._id} key={s._id}>
                            {s.first_name} {s.last_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              <div className="user_edit_buttons_section">
                <button type="submit" className="main_btn">
                  שמור
                </button>
                <button
                  type="button"
                  className="main_btn cancel_btn"
                  onClick={() => {
                    closePopupFunction();
                  }}
                >
                  חזור
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
