import { useCallback, useMemo } from "react";
import moment from "moment";
import "moment/locale/he";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style.css";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { dateRangeFromCalendar } from "../../functions/date";
import Event from "../../components/Event/event";

export default function AttendanceView({
  lessons,
  dateRangeChanged,
  style = { height: 750 },
  toolbar,
}) {
  const handleSelectEvent = useCallback((event) => {
    alert(event.teacher_name);
  }, []);

  const handleRangeChanget = useCallback(
    (range) => {
      dateRangeChanged(dateRangeFromCalendar(range));
    },
    [dateRangeChanged]
  );

  const eventStyleGetter = useCallback((event) => {
    const present_colors = {
      no: "red",
      yes: "green",
      partial: "orange",
      "not-reported": "gray",
    };
    var backgroundColor = present_colors[event.present];
    return { style: { backgroundColor } };
  }, []);

  moment.locale("he");

  const localizer = momentLocalizer(moment);
  const culture = "he";
  const messages = {
    week: "שבוע",
    work_week: "שבוע עבודה",
    day: "יום",
    month: "חודש",
    previous: "<<",
    next: ">>",
    today: "היום",
    agenda: "יומן",

    showMore: (total) => `עוד+${total}`,
  };

  const { components } = useMemo(
    () => ({
      components: {
        event: Event,
      },
    }),
    []
  );

  return (
    <Calendar
      localizer={localizer}
      culture={culture}
      components={components}
      rtl={true}
      min={new Date(0, 0, 0, 9, 0, 0)}
      max={new Date(0, 0, 0, 19, 0, 0)}
      events={lessons}
      eventPropGetter={eventStyleGetter}
      onSelectEvent={handleSelectEvent}
      defaultView={Views.WEEK}
      selectable
      messages={messages}
      onRangeChange={handleRangeChanget}
      style={style}
      toolbar={toolbar}
      views={{
        day: true,
        week: true,
        month: true,
      }}
    />
  );
}
