function Kidum({ color = "#F7931E", width = 50, height = 50 }) {
  return (
    <svg
      version="1.1"
      baseProfile="tiny"
      id="Layer_1"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 475.97 428.16"
      overflow="visible"
    >
      <path
        d="m377.63,70.44c0,1.16-.03,2.32-.1,3.45-25.65,6.83-54.23,14.47-68.86,18.5-69.35,18.98-71.89,88.42-71.89,88.42,0,0-2.58-69.44-71.92-88.42-14.66-4.03-43.24-11.67-68.86-18.5-.06-1.16-.1-2.29-.1-3.45,0-19.72,8.12-36.99,20.78-49.66C129.73,8.12,146.97,0,166.34,0s36.96,8.12,49.66,20.78c12.66,13.02,20.78,30.29,20.78,49.66,0-19.72,8.09-36.99,20.75-49.66,13.05-12.66,30.29-20.78,49.66-20.78s36.96,8.12,49.66,20.78c12.66,13.02,20.78,30.29,20.78,49.66Z"
        fill="#c30055"
      />
      <path
        d="m377.53,73.89c-.87,18.27-8.76,34.25-20.69,46.18l-119.65,119.68-.42-58.94s2.55-69.44,71.89-88.42c14.63-4.03,43.21-11.67,68.86-18.5Z"
        fill="#064679"
      />
      <path
        d="m429.06,60.16v260.05s-54.43,32.22-120.39,32.22-70.15,67.74-70.15,67.74l-1.32-180.42,119.65-119.68c11.92-11.92,19.82-27.91,20.69-46.18,27.46-7.35,51.53-13.73,51.53-13.73Z"
        fill="#005890"
      />
      <path
        d="m237.2,239.75l-.42.42-120.1-120.1c-11.92-12.25-19.79-28.23-20.69-46.18,25.62,6.83,54.2,14.47,68.86,18.5,69.35,18.98,71.92,88.42,71.92,88.42l.42,58.94Z"
        fill="#064679"
      />
      <path
        d="m238.52,420.17s-7.7-67.74-73.66-67.74-120.39-32.22-120.39-32.22V60.16s24.07,6.38,51.53,13.73c.9,17.95,8.77,33.93,20.69,46.18l120.1,120.1.42-.42,1.32,180.42Z"
        fill="#005890"
      />
      <path
        d="m355.47,428.16c-22.63,0-45.2-8.62-62.48-25.88-9.43-9.43-18.01-18.84-24.41-28.94-6.41-10.11-10.67-20.84-11.73-33.27,0,0,0-.02,0-.02,0,0,0-.02,0-.02l-8.73-94.16v-.06c-.55-5.22,1.34-10.15,4.82-13.63,2.35-2.35,5.4-4.01,8.91-4.64,5.55-.99,11.03.88,14.82,4.65,1.65,1.65,2.97,3.65,3.84,5.95l.2.46,22.47,44.56c.04.07.09.14.13.2.09.14.17.28.27.41s.2.26.31.38c.06.06.1.13.16.2.04.04.09.07.14.11.13.12.26.22.39.32.12.09.23.18.36.25.13.08.27.14.4.2.14.07.28.13.42.19.13.04.28.08.42.12.14.04.29.08.44.1.15.03.3.04.45.04s.28.02.44,0,.3-.02.45-.04c.15-.03.3-.04.45-.08.13-.03.28-.08.41-.12.16-.05.32-.11.47-.19.05-.02.11-.04.17-.06.07-.04.14-.09.2-.13.14-.09.28-.17.42-.28.13-.1.26-.2.37-.3.06-.06.13-.1.2-.16l62.72-62.72c.2-.2.36-.42.52-.64l14.36-14.36c3.22-3.22,7.41-4.81,11.63-4.82,4.23,0,8.4,1.6,11.63,4.82,3.22,3.22,4.81,7.41,4.82,11.63,0,3.69-1.3,7.32-3.77,10.33l-1.17,1.17c-.2.2-.36.42-.52.64l-35.9,35.9c-1.82,1.82-1.83,4.79,0,6.62s4.78,1.82,6.61,0l20.93-20.93c.23-.16.45-.33.67-.54l14.94-14.94c.4-.4.76-.83,1.13-1.26l13.64-13.64c.12-.12.2-.26.31-.38,3.19-3.06,7.25-4.6,11.38-4.61,4.23,0,8.4,1.6,11.63,4.82,3.22,3.22,4.81,7.41,4.82,11.63,0,4.23-1.6,8.4-4.82,11.63l-29.87,29.87c-.2.2-.37.43-.53.66l-30.81,30.81c-1.82,1.82-1.83,4.79,0,6.62s4.78,1.82,6.61,0l46.35-46.35c.11-.11.19-.22.28-.33,3.18-3.02,7.21-4.54,11.3-4.55,4.23,0,8.4,1.6,11.63,4.82,3.22,3.22,4.81,7.41,4.82,11.63,0,4.23-1.6,8.4-4.82,11.63l-14.37,14.37c-.22.15-.45.32-.64.52l-44.97,44.97c-1.82,1.82-1.83,4.79,0,6.62s4.78,1.82,6.61,0l30.03-30.03c3.22-3.22,7.41-4.81,11.63-4.82,4.23,0,8.4,1.6,11.63,4.82,3.22,3.22,4.81,7.41,4.82,11.63,0,4.23-1.6,8.4-4.82,11.63l-8.28,8.28c-.2.15-.4.29-.59.48l-44.27,44.27c-17.29,17.3-39.87,25.91-62.48,25.92h0Z"
        fill="#f0836e"
      />
      <path
        d="m120.5,428.16c22.63,0,45.2-8.62,62.48-25.88,9.43-9.43,18.01-18.84,24.41-28.94,6.41-10.11,10.67-20.84,11.73-33.27,0,0,0-.02,0-.02,0,0,0-.02,0-.02l8.73-94.16v-.06c.55-5.22-1.34-10.15-4.82-13.63-2.35-2.35-5.4-4.01-8.91-4.64-5.55-.99-11.03.88-14.82,4.65-1.65,1.65-2.97,3.65-3.84,5.95l-.2.46-22.47,44.56c-.04.07-.09.14-.13.2-.09.14-.17.28-.27.41s-.2.26-.31.38c-.06.06-.1.13-.16.2-.04.04-.09.07-.14.11-.13.12-.26.22-.39.32-.12.09-.23.18-.36.25-.13.08-.27.14-.4.2-.14.07-.28.13-.42.19-.13.04-.28.08-.42.12-.14.04-.29.08-.44.1-.15.03-.3.04-.45.04s-.28.02-.44,0-.3-.02-.45-.04c-.15-.03-.3-.04-.45-.08-.13-.03-.28-.08-.41-.12-.16-.05-.32-.11-.47-.19-.05-.02-.11-.04-.17-.06-.07-.04-.14-.09-.2-.13-.14-.09-.28-.17-.42-.28-.13-.1-.26-.2-.37-.3-.06-.06-.13-.1-.2-.16l-62.72-62.72c-.2-.2-.36-.42-.52-.64l-14.36-14.36c-3.22-3.22-7.41-4.81-11.63-4.82-4.23,0-8.4,1.6-11.63,4.82-3.22,3.22-4.81,7.41-4.82,11.63,0,3.69,1.3,7.32,3.77,10.33l1.17,1.17c.2.2.36.42.52.64l35.9,35.9c1.82,1.82,1.83,4.79,0,6.62s-4.78,1.82-6.61,0l-20.93-20.93c-.23-.16-.45-.33-.67-.54l-14.94-14.94c-.4-.4-.76-.83-1.13-1.26l-13.64-13.64c-.12-.12-.2-.26-.31-.38-3.19-3.06-7.25-4.6-11.38-4.61-4.23,0-8.4,1.6-11.63,4.82-3.22,3.22-4.81,7.41-4.82,11.63,0,4.23,1.6,8.4,4.82,11.63l29.87,29.87c.2.2.37.43.53.66l30.81,30.81c1.82,1.82,1.83,4.79,0,6.62s-4.78,1.82-6.61,0l-46.35-46.35c-.11-.11-.19-.22-.28-.33-3.18-3.02-7.21-4.54-11.3-4.55-4.23,0-8.4,1.6-11.63,4.82-3.22,3.22-4.81,7.41-4.82,11.63,0,4.23,1.6,8.4,4.82,11.63l14.37,14.37c.22.15.45.32.64.52l44.97,44.97c1.82,1.82,1.83,4.79,0,6.62s-4.78,1.82-6.61,0l-30.03-30.03c-3.22-3.22-7.41-4.81-11.63-4.82-4.23,0-8.4,1.6-11.63,4.82-3.22,3.22-4.81,7.41-4.82,11.63,0,4.23,1.6,8.4,4.82,11.63l8.28,8.28c.2.15.4.29.59.48l44.27,44.27c17.29,17.3,39.87,25.91,62.48,25.92h0Z"
        fill="#f0836e"
      />
      <polygon
        points="256.85 340.04 267.82 428.16 355.47 428.16 256.85 340.04"
        fill="#f0836e"
      />
      <polygon
        points="218.86 340.04 207.9 428.16 120.24 428.16 218.86 340.04"
        fill="#f0836e"
      />
    </svg>
  );
}

export default Kidum;
