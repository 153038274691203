import axios from "axios";
import EventBus from "./eventBus";

const apiWrapper = {
  async get(url, config) {
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        console.log("oops: ", url);
        EventBus.dispatch("logout");
      }
      throw error;
    }
  },

  async post(url, body, config) {
    try {
      const { data } = await axios.post(url, body, config);
      return data;
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        EventBus.dispatch("logout");
      }
      throw error;
    }
  },

  async put(url, body, config) {
    try {
      const { data } = await axios.put(url, body, config);
      return data;
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        EventBus.dispatch("logout");
      }
      throw error;
    }
  },

  async delete(url, config) {
    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        EventBus.dispatch("logout");
      }
      throw error;
    }
  },
};

export default apiWrapper;
